interface OwnProps {
  className?: string
  style?: React.CSSProperties
}

const CalendarPlaybook = ({ className = '', style = {} }: OwnProps) => (
  <svg viewBox="0 0 136.371 124.413" className={className} style={style}>
    <g transform="translate(-114.665 -1039.81)">
      <g transform="translate(115.664 1040.81)">
        <g transform="translate(0 0)">
          <path
            d="M5928.6,1401.447h21.76v107.431H5823.1V1401.447h21.513"
            transform="translate(-5823.102 -1392.155)"
          />
          <line x2="65.746" transform="translate(30.592 9.293)" />
          <path
            d="M5843.224,1401.447H5823.1v25.018h127.26v-25.018H5928.6"
            transform="translate(-5823.102 -1392.155)"
          />
          <line x1="64.545" transform="translate(30.592 9.293)" />
          <rect
            width="13.302"
            height="13.302"
            transform="translate(34.504 45.021)"
          />
          <rect
            width="13.302"
            height="13.302"
            transform="translate(58.17 45.021)"
          />
          <rect
            width="13.302"
            height="13.302"
            transform="translate(81.835 45.021)"
          />
          <rect
            width="13.302"
            height="13.302"
            transform="translate(105.501 45.021)"
          />
          <rect
            width="13.302"
            height="13.302"
            transform="translate(10.839 67.082)"
          />
          <rect
            width="13.302"
            height="13.302"
            transform="translate(34.504 67.082)"
          />
          <rect
            width="13.302"
            height="13.302"
            transform="translate(58.17 67.082)"
          />
          <rect
            width="13.302"
            height="13.302"
            transform="translate(81.835 67.082)"
          />
          <rect
            width="13.302"
            height="13.302"
            transform="translate(105.501 67.082)"
          />
          <rect
            width="13.302"
            height="13.302"
            transform="translate(10.839 89.143)"
          />
          <rect
            width="13.302"
            height="13.302"
            transform="translate(34.504 89.143)"
          />
          <rect
            width="13.302"
            height="13.302"
            transform="translate(58.17 89.143)"
          />
          <rect
            width="13.302"
            height="13.302"
            transform="translate(81.835 89.143)"
          />
          <rect width="8.216" height="15.314" transform="translate(21.514 0)" />
          <rect width="8.216" height="15.314" transform="translate(96.338 0)" />
        </g>
        <g
          transform="translate(7.111 5.689)"
          style={{
            fill: 'var(--color-glass)',
            stroke: 'none',
          }}
        >
          <path
            d="M5928.6,1401.447h21.76v107.431H5823.1V1401.447h21.513"
            transform="translate(-5823.102 -1392.155)"
          />
          <line x2="65.746" transform="translate(30.592 9.293)" />
          <path
            d="M5843.224,1401.447H5823.1v25.018h127.26v-25.018H5928.6"
            transform="translate(-5823.102 -1392.155)"
          />
          <line x1="64.545" transform="translate(30.592 9.293)" />
          <rect
            width="13.302"
            height="13.302"
            transform="translate(34.504 45.021)"
          />
          <rect
            width="13.302"
            height="13.302"
            transform="translate(58.17 45.021)"
          />
          <rect
            width="13.302"
            height="13.302"
            transform="translate(81.835 45.021)"
          />
          <rect
            width="13.302"
            height="13.302"
            transform="translate(105.501 45.021)"
          />
          <rect
            width="13.302"
            height="13.302"
            transform="translate(10.839 67.082)"
          />
          <rect
            width="13.302"
            height="13.302"
            transform="translate(34.504 67.082)"
          />
          <rect
            width="13.302"
            height="13.302"
            transform="translate(58.17 67.082)"
          />
          <rect
            width="13.302"
            height="13.302"
            transform="translate(81.835 67.082)"
          />
          <rect
            width="13.302"
            height="13.302"
            transform="translate(105.501 67.082)"
          />
          <rect
            width="13.302"
            height="13.302"
            transform="translate(10.839 89.143)"
          />
          <rect
            width="13.302"
            height="13.302"
            transform="translate(34.504 89.143)"
          />
          <rect
            width="13.302"
            height="13.302"
            transform="translate(58.17 89.143)"
          />
          <rect
            width="13.302"
            height="13.302"
            transform="translate(81.835 89.143)"
          />
          <rect width="8.216" height="15.314" transform="translate(21.514 0)" />
          <rect width="8.216" height="15.314" transform="translate(96.338 0)" />
        </g>
      </g>
    </g>
  </svg>
)

export default CalendarPlaybook
