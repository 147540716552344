'use client'
import useAuth from '@crystal-eyes/hooks/useAuth'
import { StatsigClient, StatsigUser } from '@statsig/js-client'
import logger from '@crystal-eyes/utils/logger'
import { STATSIG_CLIENT_KEY } from '@dashboard/lib/constants'

export default function Statsig() {
  const { data: auth } = useAuth()
  const userID = auth?.jwtPayload?.sub?.split(':')[1] || ''
  const user: StatsigUser = {
    userID: userID,
    custom: {},
  }

  logger.debug(
    'Initializing Statsig Client',
    auth?.jwtPayload?.name,
    `User: ${userID}`
  )

  const myStatsigClient = new StatsigClient(STATSIG_CLIENT_KEY, user, {
    environment: {
      tier: process.env['APP_ENV'] || process.env['NODE_ENV'],
    },
  })

  myStatsigClient.initializeAsync()

  return null
}
