interface OwnProps {
  className?: string
  style?: React.CSSProperties
}

const ShoppingCartDetailed = ({ className = '', style = {} }: OwnProps) => (
  <svg viewBox="0 0 139 107.854" className={className} style={style}>
    <g transform="translate(-118 -4585)">
      <g transform="translate(119 4586)">
        <path
          d="M5131.674,480.843h-79.157l-14.028-54.358h102.243Z"
          transform="translate(-5006.009 -409.24)"
        />
        <path
          d="M5048.627,431.868l-4.49-17.245h-27.989"
          transform="translate(-5016.147 -414.623)"
        />
        <line x2="88.453" transform="translate(40.245 44.559)" />
        <line x2="94.268" transform="translate(37.338 30.562)" />
        <line x2="82.638" transform="translate(44.607 59.258)" />
        <line y2="53.843" transform="translate(57.05 17.76)" />
        <line y2="53.843" transform="translate(78.12 17.76)" />
        <line y2="53.843" transform="translate(99.19 17.76)" />
        <line y2="53.843" transform="translate(120.261 17.76)" />
        <path
          d="M5130.089,480.475H5049.5a8.3,8.3,0,0,1-8.3-8.3h0a8.3,8.3,0,0,1,8.3-8.3h80.593"
          transform="translate(-5004.779 -392.272)"
        />
        <circle
          cx="8.506"
          cy="8.506"
          r="8.506"
          transform="translate(47.125 88.203)"
        />
        <circle
          cx="8.506"
          cy="8.506"
          r="8.506"
          transform="translate(104.23 88.203)"
        />
      </g>
      <g
        transform="translate(154.757 4604.884)"
        style={{ fill: 'var(--color-glass)', stroke: 'none' }}
      >
        <path
          d="M5131.674,480.843h-79.157l-14.028-54.358h102.243Z"
          transform="translate(-5038.488 -426.485)"
        />
        <line x2="88.453" transform="translate(7.767 27.314)" />
        <line x2="94.268" transform="translate(4.859 13.317)" />
        <line x2="82.638" transform="translate(12.128 42.013)" />
        <line y2="53.843" transform="translate(24.099 0.515)" />
        <line y2="53.843" transform="translate(45.297 0.515)" />
        <line y2="53.843" transform="translate(66.495 0.515)" />
        <line y2="53.843" transform="translate(87.782 0.515)" />
        <circle
          cx="8.506"
          cy="8.506"
          r="8.506"
          transform="translate(14.155 70.959)"
        />
        <circle
          cx="8.506"
          cy="8.506"
          r="8.506"
          transform="translate(71.751 70.959)"
        />
      </g>
    </g>
  </svg>
)

export default ShoppingCartDetailed
