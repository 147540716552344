interface OwnProps {
  className?: string
  style?: React.CSSProperties
}

const RedX = ({ className = '', style = {} }: OwnProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={className}
    style={style}
  >
    <path
      d="M18 6.2002L6 18.2002"
      stroke="#FF7C60"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 6.2002L18 18.2002"
      stroke="#FF7C60"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default RedX
