'use client'
import { FC, PropsWithChildren } from 'react'
import styles from './RouteStyleProvider.module.scss'
import classNamesBind from 'classnames/bind'
import { usePathname } from 'next/navigation'
const classNames = classNamesBind.bind(styles)

const RouteStyleProvider: FC<PropsWithChildren> = ({ children }) => {
  const pathName = usePathname()

  return (
    <body
      className={classNames('body', {
        enterprise: [
          '/app/leadership/people',
          '/app/profile-library',
          '/app/hiring/jobs',
          '/app/teams',
        ].includes(pathName || ''),
      })}
    >
      {children}
    </body>
  )
}

export default RouteStyleProvider
