import React from 'react'
import { match } from 'ts-pattern'
import classNamesBind from 'classnames/bind'
import styles from './FixedColorIcon.module.scss'
const cn = classNamesBind.bind(styles)

import Browser from './components/Browser'
import BrowserGray from './components/BrowserGray'
import CalendarDetailed from './components/CalendarDetailed'
import CalendarPlaybook from './components/CalendarPlaybook'
import CalendarPlus from './components/CalendarPlus'
import CheckmarkGreenCircle from './components/CheckmarkGreenCircle'
import CheckmarkInBox from './components/CheckmarkInBox'
import CheckmarkToggle from './components/CheckmarkToggle'
import Checklist from './components/Checklist'
import ClassicPhone from './components/ClassicPhone'
import DetailedMonitor from './components/DetailedMonitor'
import DossierBriefcase from './components/DossierBriefcase'
import ExclamationYellow from './components/ExclamationYellow'
import FeedbackLoop from './components/FeedbackLoop'
import LinkedInGray from './components/LinkedInGray'
import EyeStrikethrough from './components/EyeStrikethrough'
import HeartInHand from './components/HeartInHand'
import InfoBlue from './components/InfoBlue'
import MagnifyingGlassColor from './components/MagnifyingGlassColor'
import PriceTag from './components/PriceTag'
import RedX from './components/RedX'
import RedXCircle from './components/RedXCircle'
import RefreshDetailed from './components/RefreshDetailed'
import ShoppingCartDetailed from './components/ShoppingCartDetailed'
import SignedPaper from './components/SignedPaper'
import TakeoutCoffee from './components/TakeoutCoffee'
import ThumbsDownCuff from './components/ThumbsDownCuff'
import MagnifyingGlass from './components/MagnifyingGlass'
import XToggle from './components/XToggle'

export enum FixedColorIcons {
  Browser = 'Browser',
  BrowserGray = 'BrowserGray',
  CalendarDetailed = 'CalendarDetailed',
  CalendarPlaybook = 'CalendarPlaybook',
  ClassicPhone = 'ClassicPhone',
  CalendarPlus = 'CalendarPlus',
  CheckmarkGreenCircle = 'CheckmarkGreenCircle',
  CheckmarkInBox = 'CheckmarkInBox',
  CheckmarkToggle = 'CheckmarkToggle',
  Checklist = 'Checklist',
  DetailedMonitor = 'DetailedMonitor',
  DossierBriefcase = 'DossierBriefcase',
  ExclamationYellow = 'ExclamationYellow',
  FeedbackLoop = 'FeedbackLoop',
  EyeStrikethrough = 'EyeStrikethrough',
  LinkedInGray = 'LinkedInGray',
  HeartInHand = 'HeartInHand',
  InfoBlue = 'InfoBlue',
  MagnifyingGlassColor = 'MagnifyingGlassColor',
  PriceTag = 'PriceTag',
  RedX = 'RedX',
  RedXCircle = 'RedXCircle',
  RefreshDetailed = 'RefreshDetailed',
  ShoppingCartDetailed = 'ShoppingCartDetailed',
  SignedPaper = 'SignedPaper',
  TakeoutCoffee = 'TakeoutCoffee',
  ThumbsDownCuff = 'ThumbsDownCuff',
  XToggle = 'XToggle',
  MagnifyingGlass = 'MagnifyingGlass',
}

export type Props = {
  icon: FixedColorIcons | undefined | null
  className?: string
  style?: React.CSSProperties
}

export default function FixColorIcon({
  icon,
  className = '',
  style = {},
}: Props) {
  const FixedColorIconComponent = match(icon)
    .with(FixedColorIcons.Checklist, () => (
      <Checklist style={style} className={className} />
    ))
    .with(FixedColorIcons.HeartInHand, () => (
      <HeartInHand style={style} className={className} />
    ))
    .with(FixedColorIcons.DossierBriefcase, () => (
      <DossierBriefcase style={style} className={className} />
    ))
    .with(FixedColorIcons.FeedbackLoop, () => (
      <FeedbackLoop style={style} className={className} />
    ))
    .with(FixedColorIcons.CalendarPlaybook, () => (
      <CalendarPlaybook style={style} className={className} />
    ))
    .with(FixedColorIcons.PriceTag, () => (
      <PriceTag style={style} className={className} />
    ))
    .with(FixedColorIcons.DetailedMonitor, () => (
      <DetailedMonitor style={style} className={className} />
    ))
    .with(FixedColorIcons.RefreshDetailed, () => (
      <RefreshDetailed style={style} className={className} />
    ))
    .with(FixedColorIcons.ThumbsDownCuff, () => (
      <ThumbsDownCuff style={style} className={className} />
    ))
    .with(FixedColorIcons.SignedPaper, () => (
      <SignedPaper style={style} className={className} />
    ))
    .with(FixedColorIcons.ShoppingCartDetailed, () => (
      <ShoppingCartDetailed style={style} className={className} />
    ))
    .with(FixedColorIcons.CheckmarkGreenCircle, () => (
      <CheckmarkGreenCircle style={style} className={className} />
    ))
    .with(FixedColorIcons.ExclamationYellow, () => (
      <ExclamationYellow style={style} className={className} />
    ))
    .with(FixedColorIcons.RedXCircle, () => (
      <RedXCircle style={style} className={className} />
    ))
    .with(FixedColorIcons.InfoBlue, () => (
      <InfoBlue style={style} className={className} />
    ))
    .with(FixedColorIcons.CheckmarkInBox, () => (
      <CheckmarkInBox style={style} className={className} />
    ))
    .with(FixedColorIcons.Browser, () => (
      <Browser style={style} className={className} />
    ))
    .with(FixedColorIcons.XToggle, () => (
      <XToggle style={style} className={className} />
    ))
    .with(FixedColorIcons.CheckmarkToggle, () => (
      <CheckmarkToggle style={style} className={className} />
    ))
    .with(FixedColorIcons.MagnifyingGlassColor, () => (
      <MagnifyingGlassColor style={style} className={className} />
    ))
    .with(FixedColorIcons.BrowserGray, () => (
      <BrowserGray style={style} className={className} />
    ))
    .with(FixedColorIcons.CalendarPlus, () => (
      <CalendarPlus className={className} style={style} />
    ))
    .with(FixedColorIcons.CalendarDetailed, () => (
      <CalendarDetailed style={style} className={className} />
    ))
    .with(FixedColorIcons.RedX, () => (
      <RedX style={style} className={className} />
    ))
    .with(FixedColorIcons.LinkedInGray, () => (
      <LinkedInGray style={style} className={className} />
    ))
    .with(FixedColorIcons.TakeoutCoffee, () => (
      <TakeoutCoffee style={style} className={className} />
    ))
    .with(FixedColorIcons.EyeStrikethrough, () => (
      <EyeStrikethrough style={style} className={className} />
    ))
    .with(FixedColorIcons.ClassicPhone, () => (
      <ClassicPhone style={style} className={className} />
    ))
    .with(FixedColorIcons.MagnifyingGlass, () => (
      <MagnifyingGlass style={style} className={className} />
    ))
    .with(null, () => null)
    .with(undefined, () => null)
    .exhaustive()

  return FixedColorIconComponent
    ? React.cloneElement(FixedColorIconComponent, {
        xlmns: 'http://www.w3.org/2000/svg',
        className: cn(styles.svg, className, 'icon', `icon-${icon}`),
        style,
      })
    : null
}
