import useSWR from 'swr'
import useAuth from '@crystal-eyes/hooks/useAuth'
import {
  queryWithAuth,
  queryWithoutAuth,
  gql,
} from '@crystal-eyes/utils/apis/graphqlApi'

interface Identity {
  id: string
  personalInfo?: PersonalInfo
}

export type PersonalInfo = {
  firstName?: string
  lastName?: string
  fullName?: string
  photoUrl?: string
  jobTitle?: string
  companyName?: string
  linkedinUrl?: string
  gender?: string
}

type Options = {
  identity?: Identity
}

export type State = {
  data?: PersonalInfo
  loading?: boolean
  error?: any
}

export default function usePersonalInfo({ identity }: Options = {}): State {
  const { data: auth } = useAuth()

  const fetcher = ([_key, _authedUser, variables]: [string, string, object]) =>
    auth?.authToken
      ? queryWithAuth(auth, PERSONAL_INFO_QUERY, variables)
      : queryWithoutAuth(PUBLIC_PERSONAL_INFO_QUERY, variables).then(
          resp => resp.public,
        )

  const query = identity
    ? ['usePersonalInfo', auth?.jwtPayload?.sub, { id: identity.id }]
    : null

  const {
    data: personalInfoData,
    isLoading: loading,
    error: error,
  } = useSWR(query, fetcher, {
    revalidateOnFocus: false,
    keepPreviousData: true,
    fallbackData: { identity },
  })

  return {
    data: personalInfoData?.identity?.personalInfo,
    loading,
    error,
  }
}

const PERSONAL_INFO_QUERY = gql`
  query UsePersonalInfo($id: Guid!) {
    identity(id: $id) {
      personalInfo {
        fullName
        firstName
        lastName
        photoUrl
        jobTitle
        companyName
        linkedinUrl
        gender
      }
    }
  }
`

const PUBLIC_PERSONAL_INFO_QUERY = gql`
  query UsePersonalInfoPublic($id: Guid!) {
    public {
      identity(id: $id) {
        personalInfo {
          fullName
          firstName
          lastName
          photoUrl
          jobTitle
          companyName
        }
      }
    }
  }
`
