'use client'

import { useEffect, useState } from 'react'
import styles from './AvatarCircle.module.scss'
import ProfileBadge from '../../views/profiles/ProfileBadge'
import Icon, { Icons } from '../Icon/Icon'
import DefaultImage from './DefaultImage'

export type AvatarCircleProps = {
  className?: string
  style?: React.CSSProperties
  r: number
  includeVerified?: boolean
  isCrystalUser?: boolean
  name?: string
  discType?: string
  photoUrl?: string | null
  hasStar?: boolean
  archetype?: string
}

export default function AvatarCircle({
  r,
  className = '',
  style = {},
  includeVerified,
  isCrystalUser,
  name,
  discType,
  photoUrl,
  hasStar,
  archetype,
}: AvatarCircleProps) {
  const [fallbackPhoto, setFallbackPhoto] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [preserveAspectRatio, setPreserveAspectRatio] = useState('none')
  const isVerified = includeVerified && isCrystalUser
  const initials = name
    ?.split(' ')
    .map(n => n[0])
    .join('')

  useEffect(() => {
    setLoading(false)
  }, [])

  const handleImageError = () => {
    setFallbackPhoto(true)
  }

  const handleImageLoad = () => {
    if (photoUrl && !fallbackPhoto) {
      setPreserveAspectRatio('xMidYMid slice')
    }
  }

  const getAvatar = () => {
    if (photoUrl && !fallbackPhoto) {
      return (
        <image
          href={photoUrl || ''}
          width={r * 2}
          height={r * 2}
          preserveAspectRatio={preserveAspectRatio}
          clipPathUnits="objectBoundingBox"
          onError={handleImageError}
          onLoad={handleImageLoad}
        />
      )
    } else if (initials) {
      return (
        <>
          <circle
            cx={r}
            cy={r}
            r={r}
            className={`${`CE fill-disc ${discType || 'noDisc'}`}`}
          />
          <text
            x="50%"
            y="50%"
            textAnchor="middle"
            fill="white"
            dy=".3em"
            style={{ fontSize: `${r * 0.9}px` }}
          >
            {initials}
          </text>
        </>
      )
    } else {
      return <DefaultImage size={r * 2} />
    }
  }

  return (
    <div
      className={`${styles.avatarContainer} ${className}`}
      style={isVerified ? { width: r * 2, height: r * 2 } : {}}
    >
      <svg
        className={`${styles.avatarSVG} ${className}`}
        viewBox={`0 0 ${r * 2} ${r * 2}`}
        width={r * 2}
        height={r * 2}
        style={style}
      >
        {!loading && getAvatar()}
      </svg>
      {isVerified && (
        <div style={{ position: 'absolute', top: '-0.2rem', right: '-1rem' }}>
          <ProfileBadge style={{ marginLeft: '-25%' }} type="verified" />
        </div>
      )}
      {hasStar && (
        <div className={`${styles.star} CE disc-color Id`}>
          <Icon icon={Icons.Star} className="CE fill-disc Id" />
        </div>
      )}
      {loading && <EmptyAvatar size={r * 2} />}
      {archetype && discType && (
        <div
          className={`${styles.textPill} ${
            discType
              ? `CE disc-background-light disc-color ${discType}`
              : `CE title small white`
          }`}
        >
          {`${archetype} (${discType})`}
        </div>
      )}
    </div>
  )
}

export function EmptyAvatar({ size }: { size: number }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 28 28"
      fill="none"
      className={styles.emptyAvatar}
    >
      <circle cx="14" cy="14" r="14" fill="#C4C4C4" />
    </svg>
  )
}
