interface OwnProps {
  className?: string
  style?: React.CSSProperties
}

const CheckmarkGreenCircle = ({ className = '', style = {} }: OwnProps) => (
  <svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <g id="Green check">
      <circle
        id="Ellipse 329"
        cx="5"
        cy="6"
        r="5"
        fill="#E7F7E8"
        stroke="none"
      />
      <path
        id="Vector 7"
        d="M1.875 5.82143L5 8.5L10 1"
        stroke="#11B21B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)

export default CheckmarkGreenCircle
