'use client'

import { useState } from 'react'
import styles from './CreditLedger.module.scss'
import { Icons } from '@crystal-eyes/components/elements/Icon/Icon'
import { FixedColorIcons } from '@crystal-eyes/components/elements/Icon/FixedColorIcon/FixedColorIcon'
import Table from '@dashboard/components_v2/Table'
import LoadingBars from '@crystal-eyes/components/elements/Loader/LoadingBars'
import useMeV2 from '@crystal-eyes/hooks/user/useMeV2'
import useCreditTransactions, {
  CreditTransaction,
} from '@crystal-eyes/hooks/admin/useCreditTransactions'
import { formatDateFilterString } from '@dashboard/lib/date'
import Pagination from '../Pagination'
import useOrganization from '@crystal-eyes/hooks/contextual/useOrganization'
import { fontVariables } from '@dashboard/styles/fonts'

export const PREDICTION_SOURCES = {
  API: {
    title: 'api',
    note: 'API lookup',
    icon: FixedColorIcons.Browser,
  },
  CALENDAR_INTEGRATION: {
    title: 'calendar integration',
    note: 'Lookup from calendar integration',
    icon: FixedColorIcons.Browser,
  },
  CHROME_GMAIL_WEB: {
    title: 'gmail',
    note: 'Gmail lookup',
    icon: FixedColorIcons.Browser,
  },
  CHROME_HUBSPOT_WEB: {
    title: 'hubspot',
    note: 'Hubspot lookup',
    icon: FixedColorIcons.Browser,
  },
  CHROME_LINKEDIN: {
    title: 'linkedin',
    note: 'LinkedIn lookup',
    icon: FixedColorIcons.Browser,
  },
  CHROME_OUTLOOK_WEB: {
    title: 'outlook',
    note: 'Outlook lookup',
    icon: FixedColorIcons.Browser,
  },
  CHROME_SALES_NAVIGATOR: {
    title: 'linkedin',
    note: 'LinkedIn Sales Navigator lookup',
    icon: FixedColorIcons.Browser,
  },
  CHROME_SALESFORCE_WEB: {
    title: 'salesforce',
    note: 'Salesforce lookup',
    icon: FixedColorIcons.Browser,
  },
  CSV_ENRICHMENT: {
    title: 'csv',
    note: 'CSV enrichment',
    icon: FixedColorIcons.Browser,
  },
  DASHBOARD: {
    title: 'crystal dashboard',
    note: 'Crystal dashboard lookup',
    icon: FixedColorIcons.Browser,
  },
  OUTLOOK_VSTO: {
    title: 'outlook',
    note: 'Outlook lookup',
    icon: FixedColorIcons.Browser,
  },
  PARAGON_HUBSPOT: {
    title: 'hubspot',
    note: 'Hubspot lookup',
    icon: FixedColorIcons.Browser,
  },
  PARAGON_SALESFORCE: {
    title: 'salesforce',
    note: 'Salesforce lookup',
    icon: FixedColorIcons.Browser,
  },
  ZAPIER: {
    title: 'integration',
    note: 'Integration lookup',
    icon: FixedColorIcons.Browser,
  },
}

export const CREDIT_LEDGER_ACTIONS = {
  BILLING_CYCLE_REFRESH: {
    activity: 'Credit renewal',
    note: 'Billing cycle refreshed',
    icon: Icons.Refresh,
  },
  CRYSTAL_CORRECTION: {
    activity: 'Credit correction',
    note: 'Crystal made a correction to your account',
    icon: Icons.Refresh,
  },
  CRYSTAL_REWARD: {
    activity: 'Credit Gained',
    note: 'Received a Crystal reward',
    icon: Icons.Refresh,
  },
  CRYSTAL_REWARD_EXPIRED: {
    activity: 'Reward expiration',
    note: 'Crystal Reward expired',
    icon: Icons.Refresh,
  },
  FREE_PLAN_CREATION: {
    activity: 'Account creation',
    note: 'Free account created',
    icon: Icons.Refresh,
  },
  NEW_LICENSE_ADDED: {
    activity: 'License added',
    note: 'Added license to account',
    icon: Icons.Refresh,
  },
  PAID_PLAN_CREATION: {
    activity: 'Account creation',
    note: 'Business account created',
    icon: Icons.Refresh,
  },
  PLAN_CANCELLATION: {
    activity: 'Cancelled plan',
    note: 'Crystal plan cancelled',
    icon: Icons.Refresh,
  },
  PROFILE_CREATED: {
    activity: 'Credit used',
    note: 'Used a profile credit',
    icon: Icons.User,
  },
}

const itemsPerPage = 10

const CreditLedger = () => {
  const [page, setPage] = useState<number>(1)
  const { data: creditTransactionsData, loading: creditTransactionsLoading } =
    useCreditTransactions({ page: page, pageSize: 10 })
  const { data: me } = useMeV2()
  const { data: orgData } = useOrganization()
  const companyName =
    orgData?.org?.name || me?.identity?.personalInfo?.companyName

  const totalCount =
    creditTransactionsData?.paginatedCreditTransactions?.metadata?.totalCount
  const pageCount = totalCount ? Math.ceil(totalCount / itemsPerPage) : 0

  const getNotesString = (transaction: CreditTransaction) => {
    const fullName =
      transaction?.profileCreated?.identity?.personalInfo?.fullName || ''
    const source = transaction?.profileCreated?.source
    if (source) {
      return `${
        PREDICTION_SOURCES[source]?.note
      } ${fullName ? `for ${fullName}` : ''}`
    }
    return fullName
  }

  const getDateWithTime = (date: string) => {
    const newDate = new Date(date)
    const day = formatDateFilterString(newDate)
    const time = new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    }).format(newDate)
    return `${day} at ${time}`
  }

  const companyTitleString = `${
    companyName ? companyName : "My organization's"
  } credit usage`

  return (
    <div className={`${styles.ledgerWrapper} ${fontVariables}`}>
      <div className="CE title blue">{companyTitleString}</div>
      <LoadingBars loading={creditTransactionsLoading} count={7}>
        <Table className={styles.table}>
          <thead>
            <Table.Row>
              <Table.Header>ACTIVITY</Table.Header>
              <Table.Header>NOTES</Table.Header>
              <Table.Header>DATE + TIME</Table.Header>
            </Table.Row>
          </thead>
          <tbody>
            {creditTransactionsData?.paginatedCreditTransactions?.creditTransactions.map(
              transaction => (
                <Table.Row key={transaction.id}>
                  <Table.Cell>
                    {transaction.actionCode
                      ? CREDIT_LEDGER_ACTIONS[transaction?.actionCode].activity
                      : ''}
                  </Table.Cell>
                  <Table.Cell>
                    {transaction.actionCode === 'PROFILE_CREATED' &&
                    transaction.profileCreated
                      ? getNotesString(transaction)
                      : CREDIT_LEDGER_ACTIONS[transaction.actionCode].note}
                  </Table.Cell>
                  <Table.Cell>
                    {getDateWithTime(transaction.createdAt)}
                  </Table.Cell>
                </Table.Row>
              ),
            )}
          </tbody>
        </Table>
      </LoadingBars>

      <div className={styles.pagination}>
        <Pagination
          changePage={page => setPage(page)}
          currentPage={page}
          totalPages={pageCount}
        />
      </div>
    </div>
  )
}

export default CreditLedger
