interface OwnProps {
  className?: string
  style?: React.CSSProperties
}

const PriceTag = ({ className = '', style = {} }: OwnProps) => (
  <svg viewBox="0 0 131.748 126.776" className={className} style={style}>
    <g transform="translate(-147.005 -4775)">
      <g transform="translate(148 4776)">
        <path
          d="M6766.292,1342.533l-50.716,50.716-68.625-68.626,3.163-46.622,47.554-4.094Z"
          transform="translate(-6637.245 -1273.907)"
        />
        <circle
          cx="4.225"
          cy="4.225"
          r="4.225"
          transform="translate(22.075 13.432)"
        />
        <path
          d="M6664.941,1286.736c-6.594-1.676-23.065,6.23-24.8,22.58-4.624,43.641,68.386,45.68,30.314,84.125"
          transform="translate(-6639.933 -1269.079)"
        />
        <circle
          cx="23.662"
          cy="23.662"
          r="23.662"
          transform="translate(39.299 28.794)"
        />
      </g>
      <g
        transform="translate(159.412 4775.999)"
        style={{ fill: 'var(--color-glass)', stroke: 'none' }}
      >
        <path
          d="M68.626,119.343,0,50.717,3.163,4.094,50.716,0l68.626,68.626L68.626,119.343h0Zm-15.37-90.55a23.6,23.6,0,1,0,9.211,1.86A23.5,23.5,0,0,0,53.257,28.794ZM16.6,13.433a4.226,4.226,0,1,0,4.225,4.226A4.23,4.23,0,0,0,16.6,13.433Z"
          transform="translate(0 0)"
        />
      </g>
    </g>
  </svg>
)

export default PriceTag
