interface OwnProps {
  className?: string
  style?: React.CSSProperties
}

const HeartInHand = ({ className = '', style = {} }: OwnProps) => (
  <svg viewBox="0 0 133.432 116.722" className={className} style={style}>
    <g transform="translate(-1232 -7192)">
      <g transform="translate(1233 7193)">
        <rect
          width="16.306"
          height="49.325"
          transform="translate(16.306 113.037) rotate(180)"
          style={{ fill: 'var(--color-glass)' }}
        />
        <path
          d="M2288.162,2196.3s18.573-14.826,44.157-.444l20.462-.3s5.157-.465,5.157,4.51c0,5.2-3.994,4.987-3.994,4.987l-32.687,1.152"
          transform="translate(-2271.856 -2121.771)"
        />
        <path
          d="M2288.162,2228.627s18.769-3.641,35.112.462,23.9,1.081,23.9,1.081l53.928-34.56s4.429-3.345.615-8.155c-3.837-4.84-8.391-1.709-8.391-1.709l-34.855,17.464"
          transform="translate(-2271.856 -2123.911)"
        />
        <path
          className="CE Parented fill-disc-light"
          d="M2350.948,2142.6c-6.444,0-12.073,5.042-15.122,10.223-3.047-5.181-8.676-10.223-15.122-10.223a17.537,17.537,0,0,0-17.535,17.536c0,21.223,32.657,43.3,32.657,43.3s32.659-21.826,32.659-43.3A17.537,17.537,0,0,0,2350.948,2142.6Z"
          transform="translate(-2265.203 -2142.599)"
          style={{
            fill: 'var(--color-glass)',
          }}
        />
      </g>
    </g>
  </svg>
)

export default HeartInHand
