interface OwnProps {
  className?: string
  style?: React.CSSProperties
}

const CalendarPlus = ({ className = '', style = {} }: OwnProps) => (
  <svg
    width="109"
    height="97"
    viewBox="0 0 109 97"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M74.8999 7.5H90.1001V82.8H1V7.5H16"
      stroke="#E3E3E3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.3999 7.5H68.5"
      stroke="#E3E3E3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.1001 7.5H1V25.1H90.1001V7.5H74.8999"
      stroke="#E3E3E3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M67.6001 7.5H22.3999"
      stroke="#E3E3E3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.3999 32.6001H25.1001V41.9001H34.3999V32.6001Z"
      stroke="#E3E3E3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.0002 32.6001H41.7002V41.9001H51.0002V32.6001Z"
      stroke="#E3E3E3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M67.5999 32.6001H58.2998V41.9001H67.5999V32.6001Z"
      stroke="#E3E3E3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M84.2 32.6001H74.8999V41.9001H84.2V32.6001Z"
      stroke="#E3E3E3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.8 48H8.5V57.3H17.8V48Z"
      stroke="#E3E3E3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.3999 48H25.1001V57.3H34.3999V48Z"
      stroke="#E3E3E3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.0002 48H41.7002V57.3H51.0002V48Z"
      stroke="#E3E3E3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M67.5999 48H58.2998V57.3H67.5999V48Z"
      stroke="#E3E3E3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M84.2 48H74.8999V57.3H84.2V48Z"
      stroke="#E3E3E3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.8 63.5H8.5V72.8H17.8V63.5Z"
      stroke="#E3E3E3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.3999 63.5H25.1001V72.8H34.3999V63.5Z"
      stroke="#E3E3E3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.0002 63.5H41.7002V72.8H51.0002V63.5Z"
      stroke="#E3E3E3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M67.5999 63.5H58.2998V72.8H67.5999V63.5Z"
      stroke="#E3E3E3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.8 1H16V11.7H21.8V1Z"
      stroke="#E3E3E3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M74.3 1H68.5V11.7H74.3V1Z"
      stroke="#E3E3E3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M84.0498 95.3049C96.7524 95.3049 107.05 85.0075 107.05 72.3049C107.05 59.6024 96.7524 49.3049 84.0498 49.3049C71.3473 49.3049 61.0498 59.6024 61.0498 72.3049C61.0498 85.0075 71.3473 95.3049 84.0498 95.3049Z"
      fill="white"
      stroke="#E3E3E3"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M84.0498 56.5334V86.1554"
      stroke="#E3E3E3"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M98.8608 71.3445H69.2388"
      stroke="#E3E3E3"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default CalendarPlus
