interface OwnProps {
  className?: string
  style?: React.CSSProperties
}

const MagnifyingGlass = ({ className = '', style = {} }: OwnProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    className={className}
    style={style}
  >
    <path
      d="M4.54593 8.61483C6.46052 8.61483 8.01261 7.0627 8.01261 5.14811C8.01261 3.23353 6.46052 1.68148 4.54593 1.68148C2.63135 1.68148 1.07927 3.23353 1.07927 5.14811C1.07927 7.0627 2.63135 8.61483 4.54593 8.61483Z"
      stroke="#72F5C8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.846 10.4146L7.146 7.64795"
      stroke="#72F5C8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default MagnifyingGlass
