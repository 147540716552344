interface OwnProps {
  className?: string
  style?: React.CSSProperties
}

const XToggle = ({ className = '', style = {} }: OwnProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 29 29"
    fill="none"
    className={className}
    style={style}
  >
    <circle stroke="none" cx="14.5" cy="14.5" r="14.5" fill="#DB3C48" />
    <path
      d="M22.39 6.45044L7.00002 21.8377"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M22.2855 21.7333L7.00396 6.45439"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
)

export default XToggle
