import { gql, authedQuery } from '@crystal-eyes/utils/apis/graphqlApi'
import getAuth from '@crystal-eyes/data/getAuth'
import { DiscType } from '@crystal-eyes/types'

export type UserIdentity = {
  id: string
  personalInfo: {
    firstName?: string
    lastName?: string
    fullName?: string
    photoUrl?: string
    linkedinUrl?: string
  }

  rootSnapshot: {
    id: string
    vanityUrl?: string
  }

  snapshot: {
    id: string
    personality: {
      id: string
      discType?: DiscType
      discCircumplex?: {
        degrees: number
        intensity: number
      }
    }
  }
}

export default async function getUserIdentity(): Promise<UserIdentity | null> {
  const auth = await getAuth()
  if (!auth.authed) return null

  return authedQuery(USER_IDENTITY_QUERY).then(res => res.me.identity)
}

const USER_IDENTITY_QUERY = gql`
  query getUserIdentity {
    me {
      identity {
        id

        personalInfo {
          firstName
          lastName
          fullName
          photoUrl
          linkedinUrl
        }

        rootSnapshot {
          id
          vanityUrl
        }

        snapshot {
          id
          personality {
            id
            discType
            discCircumplex {
              degrees
              intensity
            }
          }
        }
      }
    }
  }
`
