interface OwnProps {
  className?: string
  style?: React.CSSProperties
}

const DossierBriefcase = ({ className = '', style = {} }: OwnProps) => (
  <svg viewBox="0 0 136.726 96.884" className={className} style={style}>
    <g transform="translate(1.297 1)">
      <g transform="translate(0 0)">
        <rect width="130.631" height="80.792" transform="translate(0 10.593)" />
        <line y1="21.153" x2="53.216" transform="translate(77.415 29.809)" />
        <line x2="53.205" y2="21.009" transform="translate(0 29.98)" />
        <path
          d="M3633.479,1179.314h0a10.593,10.593,0,0,1,10.592-10.593h23.592a10.593,10.593,0,0,1,10.593,10.593h0"
          transform="translate(-3591.586 -1168.721)"
        />
        <rect
          width="24.221"
          height="11.69"
          transform="translate(53.205 47.086)"
        />
      </g>
      <g
        transform="translate(3.499 3.498)"
        style={{ fill: 'var(--color-glass)', stroke: 'none' }}
      >
        <rect width="130.631" height="80.792" transform="translate(0 10.593)" />
        <line y1="21.153" x2="53.216" transform="translate(77.415 29.809)" />
        <line x2="53.205" y2="21.009" transform="translate(0 29.98)" />
        <path
          d="M3633.479,1179.314h0a10.593,10.593,0,0,1,10.592-10.593h23.592a10.593,10.593,0,0,1,10.593,10.593h0"
          transform="translate(-3591.586 -1168.721)"
        />
        <rect
          width="24.221"
          height="11.69"
          transform="translate(53.205 47.086)"
        />
      </g>
    </g>
  </svg>
)

export default DossierBriefcase
