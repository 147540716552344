import { v4 as uuidv4 } from 'uuid'
import isMobile from 'is-mobile'
import moment from 'moment-timezone'
import { IncomingMessage } from 'http'
import { ParsedUrlQuery } from 'querystring'
import md5 from 'md5'
import {
  APP_URL,
  CRYSTAL_AUTH_TOKEN,
  CRYSTAL_PAGE_TOKEN,
  CRYSTAL_SESSION_TOKEN,
} from './constants'
import { getCookie, getQueryParam, setCookie } from './browser'
import {
  AuthUserTeam,
  AccessTiers,
  FullAuthUser,
  PlanTiers,
  PlanTypes,
  ProductObject,
  Products,
  SegmentTypes,
  TaskName,
  TaskOptions,
  Team,
  TeamUser,
  USER_ACTIONS,
} from '@dashboard/lib/types'
import { rsEvent } from './analytics/helpers'

export function isAdmin(authUser: FullAuthUser) {
  return authUser.access.team.member_manager
}

export function isEnterpriseUser(authUser: FullAuthUser) {
  return !!authUser.team.enterprise_org
}

export function localAuthToken() {
  const localAuthToken = getCookie(CRYSTAL_AUTH_TOKEN)
  const authToken = localAuthToken ? localAuthToken : ''

  return authToken
}

export function sessionToken() {
  let sessionToken = getCookie(CRYSTAL_SESSION_TOKEN)

  if (!sessionToken) {
    sessionToken = uuidv4()

    setCookie(CRYSTAL_SESSION_TOKEN, sessionToken)
  }

  return sessionToken
}

export function setPageToken() {
  const date = new Date()
  const pageToken = md5(
    `${window.location.pathname}-${window.location.search}-${date.getTime()}`,
  )

  setCookie(CRYSTAL_PAGE_TOKEN, pageToken)
}

export function appName(opts?: { ua?: IncomingMessage }) {
  return isMobile(opts) ? 'mobile_dashboard' : 'dashboard'
}

function parseProductId(id: PlanTypes): number | null {
  const idArray = id.split('-')
  const tier = parseInt(idArray[2])

  if (tier) return tier

  return null
}

export function teamTier(
  authUser: FullAuthUser,
  product: Products,
): number | null {
  const chosenProduct = authUser.team[product]
  const planId = chosenProduct?.plan_id
  const parsedProductId = planId ? parseProductId(planId) : 0

  if (!parsedProductId && chosenProduct) return chosenProduct.tier
  return parsedProductId
}

export function isFreeUser(authUser: FullAuthUser): boolean {
  return (
    !isHiringUser(authUser) &&
    !isSalesUser(authUser) &&
    !isLeadershipUser(authUser)
  )
}

export function isOnFreeTeam(authUser: FullAuthUser): boolean {
  return (
    !authUser.team.leadership && !authUser.team.sales && !authUser.team.hiring
  )
}

export function isHiringUser(authUser: FullAuthUser): boolean {
  return !!authUser.access.hiring_tier
}

export function teamHasHiringProduct(authUser: FullAuthUser): boolean {
  return !!authUser.team.hiring
}

export function isSalesUser(authUser: FullAuthUser): boolean {
  return !!authUser.access.sales_tier
}

export function teamHasSalesProduct(authUser: FullAuthUser): boolean {
  return !!authUser.team.sales
}

export function isLeadershipUser(authUser: FullAuthUser): boolean {
  return !!authUser.access.leadership_tier
}

export function teamHasLeadershipProduct(authUser: FullAuthUser): boolean {
  return !!authUser.team.leadership
}

export function isTeamUser(authUser: FullAuthUser): boolean {
  return !!authUser.access.team
}

export function orgHasLeadershipProduct(authUser: FullAuthUser): boolean {
  return !!authUser.team.leadership
}

export function hiringAccessRevoked(authUser: FullAuthUser): boolean {
  return !isHiringUser(authUser) && !!authUser.team.hiring
}

export const currentUserProductTier = (
  authUser: FullAuthUser | TeamUser,
  product: Products,
): PlanTiers | null => {
  const tier = `${product}_tier` as AccessTiers
  const access = authUser?.access

  return access ? access[tier] : null
}

export const currentAccess = (authUser: FullAuthUser) => {
  const products: string[] = []

  if (isLeadershipUser(authUser)) products.push('team')
  if (isSalesUser(authUser)) products.push('sales')
  if (isHiringUser(authUser)) products.push('hiring')

  return products
}

export const currentSegment = (authUser: FullAuthUser): SegmentTypes | null => {
  return authUser.current_segment || null
}

export const productId = (
  authUser: FullAuthUser,
  productType: Products,
): string | null => {
  if (!productType) return null

  const product = authUser.team[productType]

  if (!product) return null

  return product.id
}

export function hasCustomPlan(authUser: FullAuthUser, product: Products) {
  return !!authUser.team[product]?.custom
}

export function currentPlans(
  object: FullAuthUser | AuthUserTeam | Team,
): ProductObject[] {
  const list: Products[] = ['leadership', 'sales', 'hiring']
  const team = 'team' in object ? object.team : object

  return list
    .map(product => team[product])
    .filter((plan): plan is ProductObject => !!plan)
}

const getSeatTally = (authUser: FullAuthUser, product: Products) => {
  if (product == 'sales') {
    return authUser.team.sales_seat_tally
  }

  if (product == 'leadership') {
    return authUser.team.leadership_seat_tally
  }

  if (product == 'hiring') {
    return authUser.team.candidates_tally
  }

  return null
}

export const getCandidatesRemaining = (authUser: FullAuthUser) => {
  const { allowance, usage } = authUser.team.candidates_tally || {}

  return (allowance || 0) - (usage || 0)
}

export const unassignedSeats = (authUser: FullAuthUser, product: Products) => {
  const tally = getSeatTally(authUser, product)

  const seatsUsed = tally?.usage || 0
  const seatsAvailable = tally?.allowance || 0

  return seatsAvailable - seatsUsed
}

export const seatsRemaining = (authUser: FullAuthUser) => {
  const unassignedSalesTally: number = unassignedSeats(authUser, 'sales')
  const unassignedLeadershipTally = unassignedSeats(authUser, 'leadership')
  const unassignedHiringTally = unassignedSeats(authUser, 'hiring')

  const seatsLeft =
    (unassignedSalesTally || 0) +
    (unassignedLeadershipTally || 0) +
    (unassignedHiringTally || 0)

  return seatsLeft
}

export const invitesRemaining = (authUser: FullAuthUser) => {
  const unassignedSalesTally: number = unassignedSeats(authUser, 'sales')
  const unassignedLeadershipTally = unassignedSeats(authUser, 'leadership')
  if (teamHasLeadershipProduct(authUser)) {
    return unassignedLeadershipTally
  } else if (teamHasSalesProduct(authUser)) {
    return unassignedSalesTally
  } else {
    return 0
  }
}

export const seatUsage = (authUser: FullAuthUser, product: Products) => {
  const tally = getSeatTally(authUser, product)

  return tally?.usage || 0
}

export const productUnpauseDate = (
  authUser: FullAuthUser,
  product: Products,
) => {
  return authUser.team[product]?.pause?.start_at || null
}

export const cancellationDaysRemainingFor = (
  authUser: FullAuthUser,
  product: Products,
): number | null => {
  const interval = authUser.team[product]?.subscription_ends_at || null

  return interval
    ? Math.ceil(moment.duration(moment(interval).diff(moment())).asDays())
    : null
}

export const isDeprecated = (authUser: FullAuthUser, product: Products) => {
  return !!authUser.team[product]?.deprecated
}

export function handleTasks(
  inviteTasks: (taskNames: TaskName[], options: TaskOptions) => void,
  query: ParsedUrlQuery,
  filterOut: TaskName[] = [],
): void {
  if (!query || Object.keys(query).length === 0) return

  const inviteToken = getQueryParam(query.invite_token)
  const profileId = getQueryParam(query.profile_id)
  const slackTeamId = getQueryParam(query.slack_team_id)
  const teamId = getQueryParam(query.team_id)
  const teamUnitId = getQueryParam(query.team_unit_id)
  const candidateId = getQueryParam(query.candidate_id)
  const taskNames = getQueryParam(query.tasks) || ''
  const tasks = (taskNames.split(',') as TaskName[]).filter(
    task => !filterOut.includes(task),
  )
  const options: TaskOptions = {}

  if (tasks.length === 0) return
  if (inviteToken) options.invite_token = inviteToken
  if (profileId) options.profile_id = profileId
  if (slackTeamId) options.slack_team_id = slackTeamId
  if (teamId) options.team_id = teamId
  if (teamUnitId) options.team_unit_id = teamUnitId
  if (candidateId) options.candidate_id = candidateId

  if (tasks.some(task => task === 'accept_referral')) {
    rsEvent('Partner Invite Accepted', { inviter_id: profileId })
  }

  inviteTasks(tasks, options)
}

export function isAnnualUser(
  authUser: FullAuthUser,
  product: Products,
): boolean {
  return authUser.team[product]?.interval == 'yearly'
}

export function getDowngradeTier(
  authUser: FullAuthUser,
  product: Products,
): number | null {
  const recentChange = authUser.team[product]?.recent_change

  if (recentChange) return parseProductId(recentChange.plan_id)

  return null
}

export function getDowngradeQuantity(
  authUser: FullAuthUser,
  product: Products,
): number | null {
  return authUser.team[product]?.recent_change?.quantity || null
}

export function getPlanInterval(authUser: FullAuthUser, product: Products) {
  return authUser.team[product]?.billing_cycle_at
}

export function getPrimaryEmail(authUser: FullAuthUser) {
  return authUser.emails.find(email => {
    return email.primary
  })
}

export function getDaysTillDowngrade(
  authUser: FullAuthUser,
  product: Products,
) {
  const interval = authUser.team[product]?.subscription_ends_at

  return interval
    ? Math.ceil(moment.duration(moment(interval).diff(moment())).asDays())
    : null
}

export function customTermsOfServiceNotAccepted(authUser: FullAuthUser) {
  return (
    authUser.features.custom_terms_of_service &&
    !authUser.actions[USER_ACTIONS.custom_terms]
  )
}

export function buildOrgInviteLink(
  authUser: FullAuthUser,
  additionalTasks: Exclude<TaskName, 'team_invite'>[],
  options: {
    team_unit_id?: string
    candidate_id?: string
  } = {},
): string {
  const tasks: TaskName[] = ['team_invite', ...additionalTasks]

  const optionalParams = Object.keys(options)
    .map(param => `${param}=${(options as Record<string, string>)[param]}`)
    .join('&')
  const params = optionalParams ? `&${optionalParams}` : ''

  return `${APP_URL}/app/register?team_id=${authUser.team.id}&inviter_id=${
    authUser.profile_id
  }${params}&tasks=${tasks.join(',')}`
}

export const isPaidUserBelowThreshold = (authUser: FullAuthUser | null) => {
  if (!authUser?.prediction_tally?.allowance) return null

  const { allowance, usage } = authUser.prediction_tally
  const viewsRemaining = allowance - usage

  return !isFreeUser(authUser) && viewsRemaining <= 25
}
