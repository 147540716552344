interface OwnProps {
  className?: string
  style?: React.CSSProperties
}

const TakeoutCoffee = ({ className = '', style = {} }: OwnProps) => (
  <svg viewBox="0 0 91.234 135.579" className={className} style={style}>
    <g transform="translate(-172 -5992.836)">
      <g transform="translate(173 5993.835)">
        <path
          d="M7348.6,840.78l-1.2-20.821h75.158l-1.272,20.549"
          transform="translate(-7340.931 -796.528)"
        />
        <path
          d="M7416.955,875.762l-1.842,32.009h-62.66l-1.841-32.009"
          transform="translate(-7339.736 -775.761)"
        />
        <path
          d="M7419.323,890.748h-67.764c-1.778,0-3.258-1.7-3.391-3.9l-2.833-46.591c-.156-2.573,1.484-4.767,3.564-4.767h73.082c2.081,0,3.72,2.194,3.565,4.767l-2.832,46.591C7422.58,889.048,7421.1,890.748,7419.323,890.748Z"
          transform="translate(-7341.702 -790.748)"
        />
        <rect
          width="86.882"
          height="9.703"
          rx="1.854"
          transform="translate(0 13.728)"
        />
        <path
          d="M4.816,0H70.343a4.816,4.816,0,0,1,4.816,4.816v8.912a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V4.816A4.816,4.816,0,0,1,4.816,0Z"
          transform="translate(6.468 0)"
        />
        <ellipse
          cx="18.265"
          cy="14.664"
          rx="18.265"
          ry="14.664"
          transform="matrix(0.39, -0.921, 0.921, 0.39, 25.18, 83.014)"
        />
        <path
          d="M7382.726,848.555,7376.55,855l3.135,5.92-6.874,6.57"
          transform="translate(-7331.475 -785.886)"
        />
      </g>
      <g
        transform="translate(175.352 5995.404)"
        style={{ fill: 'var(--color-glass)', stroke: 'none' }}
      >
        <path
          d="M1.2,20.822h0L0,0H75.159L73.886,20.55Z"
          transform="translate(6.468 23.43)"
        />
        <path
          d="M7416.955,875.762l-1.842,32.009h-62.66l-1.841-32.009"
          transform="translate(-7339.736 -775.761)"
        />
        <rect
          width="86.882"
          height="9.703"
          rx="1.854"
          transform="translate(0 13.728)"
        />
        <path
          d="M4.816,0H70.343a4.816,4.816,0,0,1,4.816,4.816v8.912a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V4.816A4.816,4.816,0,0,1,4.816,0Z"
          transform="translate(6.468 0)"
        />
        <ellipse
          cx="18.265"
          cy="14.664"
          rx="18.265"
          ry="14.664"
          transform="matrix(0.39, -0.921, 0.921, 0.39, 25.18, 83.014)"
        />
        <path
          d="M7382.726,848.555,7376.55,855l3.135,5.92-6.874,6.57"
          transform="translate(-7331.475 -785.886)"
        />
      </g>
    </g>
  </svg>
)

export default TakeoutCoffee
