interface OwnProps {
  className?: string
  style?: React.CSSProperties
}

const ClassicPhone = ({ className = '', style = {} }: OwnProps) => (
  <svg viewBox="0 0 135.141 135.14" className={className} style={style}>
    <g transform="translate(-131.854 -6735)">
      <path
        d="M4189.366,1496.3l-25.1-25.1a3.668,3.668,0,0,0-5.187,0l-16.923,16.924c-10.377-.476-23.065-6.6-33.784-17.314s-16.84-23.407-17.314-33.784l16.925-16.923a3.668,3.668,0,0,0,0-5.187l-25.1-25.1a3.665,3.665,0,0,0-5.185,0l-7.744,7.742c-.069.071-.1.16-.165.234-17.156,18.588-6.818,57.646,23.565,88.03s69.443,40.721,88.031,23.566c.074-.062.164-.1.233-.164l7.744-7.744A3.666,3.666,0,0,0,4189.366,1496.3Z"
        transform="translate(-3928.522 5347.256)"
      />
      <path
        style={{ fill: 'var(--color-glass)', stroke: 'none' }}
        d="M4189.366,1496.3l-25.1-25.1a3.668,3.668,0,0,0-5.187,0l-16.923,16.924c-10.377-.476-23.065-6.6-33.784-17.314s-16.84-23.407-17.314-33.784l16.925-16.923a3.668,3.668,0,0,0,0-5.187l-25.1-25.1a3.665,3.665,0,0,0-5.185,0l-7.744,7.742c-.069.071-.1.16-.165.234-17.156,18.588-6.818,57.646,23.565,88.03s69.443,40.721,88.031,23.566c.074-.062.164-.1.233-.164l7.744-7.744A3.666,3.666,0,0,0,4189.366,1496.3Z"
        transform="translate(-3924.446 5351.333)"
      />
    </g>
  </svg>
)

export default ClassicPhone
