interface OwnProps {
  className?: string
  style?: React.CSSProperties
}

const Checklist = ({ className = '', style = {} }: OwnProps) => (
  <svg viewBox="0 0 105.715 135.277" className={className} style={style}>
    <g transform="translate(-131 -1915.212)">
      <g transform="translate(132 1916.212)">
        <path
          d="M3339.328,3984.1H3238.937V3854.145h78.043l22.348,22.2Z"
          transform="translate(-3238.937 -3854.145)"
        />
        <line x2="42.48" transform="translate(42.113 34.747)" />
        <line x2="42.48" transform="translate(42.113 60.088)" />
        <line x2="42.48" transform="translate(42.113 85.429)" />
        <line x2="42.48" transform="translate(42.113 110.77)" />
        <path
          d="M3314.949,3876.344h-22.2v-22.2Z"
          transform="translate(-3214.558 -3854.145)"
        />
        <path
          d="M3264.54,3876.085v6.111h-13.708V3868.49h6.993"
          transform="translate(-3233.547 -3847.646)"
        />
        <path
          d="M3253.541,3874.9l3.059,2.978,8.915-12.022"
          transform="translate(-3232.32 -3848.839)"
        />
        <path
          d="M3264.54,3893.032v6.111h-13.708v-13.707h6.993"
          transform="translate(-3233.547 -3839.969)"
        />
        <path
          d="M3253.541,3891.846l3.059,2.978,8.915-12.022"
          transform="translate(-3232.32 -3841.161)"
        />
        <path
          d="M3264.54,3911.092v6.111h-13.708V3903.5h6.993"
          transform="translate(-3233.547 -3831.787)"
        />
        <path
          d="M3253.541,3909.907l3.059,2.978,8.915-12.022"
          transform="translate(-3232.32 -3832.98)"
        />
        <path
          d="M3264.54,3928.843v6.111h-13.708v-13.707h6.993"
          transform="translate(-3233.547 -3823.746)"
        />
        <path
          d="M3253.541,3927.657l3.059,2.978,8.915-12.022"
          transform="translate(-3232.32 -3824.938)"
        />
      </g>
      <path
        style={{ fill: 'var(--color-glass)', stroke: 'none' }}
        d="M3339.328,3984.1H3238.937V3854.145h78.043l22.348,22.2Z"
        transform="translate(-3102.613 -1933.609)"
      />
    </g>
  </svg>
)

export default Checklist
