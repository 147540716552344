'use client'

import React, { useCallback, useEffect, useRef, useState } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import logo from '@dashboard/public/static/share/images/crystal_svg_text_logo.svg'
import minimalLogo from '@dashboard/public/static/share/images/crystal_svg_logo.svg'
import { CHROME_INSTALL_URL } from '@dashboard/lib/constants'
import Notifications from '@dashboard/components_v2/global/Notifications'
import SearchBar from '@dashboard/components_v2/global/SearchBar'
import { isLookBehindSupported } from '@dashboard/lib/adaptive_messaging/string'
import { UsageActionNeeded } from '@crystal-eyes/types'
import styles from './Navigation.module.scss'
import Modal from '@dashboard/components/Modal'
import useOrganization from '@crystal-eyes/hooks/contextual/useOrganization'
import IdentityIcon from '@dashboard/components_v2/IdentityIcon'
import Icon, { Icons } from '@crystal-eyes/components/elements/Icon/Icon'
import { usePathname } from 'next/navigation'
import useMeV2, { ProductAccess } from '@crystal-eyes/hooks/user/useMeV2'
import useUsageStats from '@crystal-eyes/hooks/user/useUsageStats'
import useWhitelabelSettings from '@crystal-eyes/hooks/user/useWhitelabelSettings'
import useNotifications from '@crystal-eyes/hooks/contextual/useNotifications'
import useUserIdentity from '@crystal-eyes/hooks/user/useUserIdentity'

type SectionName =
  | 'search'
  | 'profile'
  | 'people'
  | 'tools'
  | 'notifications'
  | 'hamburger'

export type NavigationProps = {
  startMobileOpen?: boolean
  startExpanded?: SectionName | null
  public?: boolean
}

export default function NavigationAuthed({
  startMobileOpen = false,
  startExpanded = null,
}: NavigationProps) {
  const { data: me } = useMeV2()
  const { data: userIdentity } = useUserIdentity()
  const { data: whiteLabelSettings, loading: whitelabelLoading } =
    useWhitelabelSettings()
  const profileLibraryPath = '/app/profile-library'

  const [expanded, setShow] = useState(startExpanded)
  const [mobileOpen, setMobileOpen] = useState(startMobileOpen)

  const isAdmin = me?.isOrgAdmin || false
  const products = me?.products || []
  const features = me?.features || []
  const { data: usageLimits } = useUsageStats()
  const whitelabelLogo = whiteLabelSettings?.logoUrl
  const { data: notifData } = useNotifications()
  const notifications = notifData?.notifications || []

  const wrapperRef = useRef<HTMLElement>(null)
  useEffect(() => {
    /**
     * If click is outside of Nav Bar and it is opened, close it
     */
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShow(null)
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef, setShow])

  const pathname = usePathname()
  const viewerProfileId = userIdentity?.rootSnapshot?.id
  const viewerProfileVanity = userIdentity?.rootSnapshot?.vanityUrl
  const hideProfileLibrary = me?.features.includes('disable_profile_library')
  const isOnProfile: () => boolean = useCallback(() => {
    if (viewerProfileId && pathname == `/p/${viewerProfileId}`) return true
    if (viewerProfileVanity && pathname == `/p/${viewerProfileVanity}`)
      return true

    return false
  }, [viewerProfileId, viewerProfileVanity, pathname])

  return (
    <nav className={styles.navBar} ref={wrapperRef}>
      <div className="nav-areas">
        <div
          className={`primary-bar ${
            mobileOpen ? 'mobile-open' : 'mobile-close'
          }`}
        >
          <div className="logo-search-area">
            {whitelabelLogo && (
              <div className="white-label-area">
                <Link href={'/app'} aria-label="Homepage" prefetch={false}>
                  <Image
                    src={whitelabelLogo}
                    alt="Crystal Logo"
                    width={88}
                    height={42}
                  />
                </Link>

                <div className="powered-by">powered by Crystal</div>
              </div>
            )}

            {!whitelabelLogo && !whitelabelLoading && (
              <Link href={'/app'} prefetch={false}>
                <Image
                  src={logo.src}
                  alt="Crystal Logo"
                  width={128}
                  height={38}
                  className="logo-full"
                />

                <Image
                  src={minimalLogo.src}
                  alt="Crystal Logo"
                  width={32}
                  height={32}
                  className="logo-minimal"
                />
              </Link>
            )}

            <div className="search-area">
              <SearchBar />
            </div>
          </div>

          <div className="full-section-items">
            <div
              className="mobile-close-link"
              onClick={() => setMobileOpen(false)}
            >
              X
            </div>

            <NavOption>
              <SectionLink
                href={'/app'}
                isSelectedCheck={isOnProfile}
                useNormalLink
              >
                Profile
              </SectionLink>
            </NavOption>

            <NavOption
              dropdownWidth="7em"
              expanded={expanded === 'people'}
              doToggleExpanded={() =>
                setShow(curr => (curr === 'people' ? null : 'people'))
              }
            >
              <DropdownLink>People</DropdownLink>

              {products.includes(ProductAccess.Hiring) && (
                <SectionLink href={'/app/hiring/people'}>
                  Candidates
                </SectionLink>
              )}

              <SectionLink href={'/app/leadership/people'}>
                Coworkers
              </SectionLink>
              {/*<SectionLink href={'/app/leadership/viewed'}>Viewed</SectionLink>*/}
              {!hideProfileLibrary && (
                <SectionLink href={profileLibraryPath}>
                  Add Profiles
                </SectionLink>
              )}
            </NavOption>

            <NavOption
              dropdownWidth="10em"
              expanded={expanded === 'tools'}
              doToggleExpanded={() =>
                setShow(curr => (curr === 'tools' ? null : 'tools'))
              }
            >
              <DropdownLink>Tools</DropdownLink>
              <SectionLink href={CHROME_INSTALL_URL}>
                Chrome Extension
              </SectionLink>
              {products.includes(ProductAccess.Hiring) && (
                <SectionLink href={'/app/hiring/jobs'}>Jobs</SectionLink>
              )}
              {features.includes('crystal_for_outlook_vsto') && (
                <SectionLink href={'/app/outlook'}>Outlook</SectionLink>
              )}
              <SectionLink href={'/app/playbooks'}>Playbooks</SectionLink>
              <SectionLink href={'/app/sales/dashboard'}>
                Quick Tips
              </SectionLink>
              {products.includes(ProductAccess.Leadership) && (
                <SectionLink href={'/app/teams'}>Teams</SectionLink>
              )}
              {isLookBehindSupported() && (
                <SectionLink href={'/app/writing-assistant'}>
                  Writing Assistant
                </SectionLink>
              )}
            </NavOption>

            <div className="special-options">
              <NavOption
                alignLeft={true}
                optionsMarginTop={'10px'}
                expanded={expanded === 'notifications'}
                doToggleExpanded={() =>
                  setShow(curr =>
                    curr === 'notifications' ? null : 'notifications',
                  )
                }
              >
                <div className="notif-button svg-button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    style={{ width: '1.1em', height: '1.1em' }}
                  >
                    <path d="M22 17H2a3 3 0 0 0 3-3V9a7 7 0 0 1 14 0v5a3 3 0 0 0 3 3zm-8.27 4a2 2 0 0 1-3.46 0"></path>
                    {notifications.length > 0 && (
                      <circle cx="5" cy="5" r="5" fill="red" stroke="none" />
                    )}
                  </svg>
                </div>

                <Notifications />
              </NavOption>

              <NavOption
                smallScreenAlignLeft={true}
                dropdownWidth="10em"
                expanded={expanded === 'hamburger'}
                doToggleExpanded={() =>
                  setShow(curr => (curr === 'hamburger' ? null : 'hamburger'))
                }
              >
                <div className="more-button svg-button">
                  <NavOption className="mobile-more-heading">
                    <SectionLink
                      href="#"
                      isSelectedCheck={isOnProfile}
                      useNormalLink
                    >
                      More
                    </SectionLink>
                  </NavOption>

                  <svg
                    viewBox="0 0 24 24"
                    width={16}
                    height={16}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4 18L20 18"
                      strokeWidth="2"
                      strokeLinecap="round"
                    ></path>{' '}
                    <path
                      d="M4 12L20 12"
                      strokeWidth="2"
                      strokeLinecap="round"
                    ></path>{' '}
                    <path
                      d="M4 6L20 6"
                      strokeWidth="2"
                      strokeLinecap="round"
                    ></path>
                  </svg>
                </div>

                <SectionLink href={CHROME_INSTALL_URL}>
                  Install Extension
                </SectionLink>

                {isAdmin && (
                  <SectionLink href={'/app/team'}>Organization</SectionLink>
                )}

                <SectionLink href={'/app/account/settings'}>
                  Personal Settings
                </SectionLink>

                <SectionLink href={'/privacy'}>Privacy Policy</SectionLink>

                <SectionLink href={'https://docs.crystalknows.com/'}>
                  Support FAQ
                </SectionLink>

                <SectionLink href={'/tos'}>Terms of Service</SectionLink>

                <SectionLink href={'/app/logout'}>Log Out</SectionLink>
              </NavOption>
            </div>
          </div>

          <div
            className="mobile-more-button svg-button"
            onClick={() => setMobileOpen(true)}
          >
            <svg
              viewBox="0 0 24 24"
              width={16}
              height={16}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 18L20 18"
                strokeWidth="2"
                strokeLinecap="round"
              ></path>{' '}
              <path
                d="M4 12L20 12"
                strokeWidth="2"
                strokeLinecap="round"
              ></path>{' '}
              <path d="M4 6L20 6" strokeWidth="2" strokeLinecap="round"></path>
            </svg>
          </div>
        </div>
        {usageLimits &&
          usageLimits.allowance !== null &&
          usageLimits.usage !== null &&
          usageLimits.allowance - usageLimits.usage < 25 && (
            <div className="usage-limits-notice verified-limits-container">
              <span>
                <span className="allowance-text">
                  {usageLimits.allowance - usageLimits.usage} of&nbsp;
                  {usageLimits.allowance} profiles{' '}
                  <span className="remaining">remaining</span>{' '}
                </span>
                <span className="CE tooltip bottom-tooltip">
                  <Icon icon={Icons.Info} className={styles.icon} />
                  <div className="tooltip-content">
                    {`You’re able to generate ${usageLimits.allowance} profiles. Viewing these profiles again does not count towards your view.`}
                  </div>
                </span>
              </span>

              {usageLimits.actionNeeded ===
              UsageActionNeeded.ContactAdministrators ? (
                <AdminModalLink>
                  <div className="cta">Request More</div>
                </AdminModalLink>
              ) : (
                <Link href="/app/upgrade" prefetch={false}>
                  <div className="cta">Upgrade</div>
                </Link>
              )}
            </div>
          )}
      </div>
    </nav>
  )
}

function NavOption({
  className = '',
  children,
  expanded,
  doToggleExpanded = () => {
    return
  },
  dropdownWidth,
  alignLeft,
  smallScreenAlignLeft,
  optionsMarginTop,
}: {
  className?: string
  children: React.ReactNode
  expanded?: boolean
  doToggleExpanded?: () => void
  dropdownWidth?: string
  alignLeft?: boolean
  smallScreenAlignLeft?: boolean
  optionsMarginTop?: string
}) {
  const pathname = usePathname()

  let anySelected = false
  const arrayChildren = React.Children.toArray(children).map(elem => {
    if (!React.isValidElement(elem)) return elem

    if (pathname?.includes(elem.props.href)) {
      anySelected = true
      const addedProps =
        elem.type === SectionLink ? { $currentpage: 'true' } : {}

      return React.cloneElement(elem, { ...elem.props, ...addedProps })
    }

    return elem
  })

  const mainOption = arrayChildren[0]
  const secondaryOptions = arrayChildren.slice(1)
  const hasDropdownItems =
    React.isValidElement(mainOption) && secondaryOptions.length > 0

  const displayDropdown = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      evt.preventDefault()
      doToggleExpanded()
    },
    [doToggleExpanded],
  )

  if (hasDropdownItems) {
    const addedProps =
      mainOption.type === SectionLink
        ? { $currentpage: anySelected ? 'true' : 'false' }
        : {}
    return (
      <div
        className={`dropdown-area ${className} ${
          expanded ? 'show-options' : 'hide-options'
        }`}
      >
        {React.cloneElement(mainOption as any, {
          ...addedProps,
          onClick: displayDropdown,
        })}

        <div
          className={`
            dropdown-options
            ${className}
            ${alignLeft ? 'align-left' : ''}
            ${smallScreenAlignLeft ? 'small-screen-align-left' : ''}
          `}
          style={{
            ...(optionsMarginTop ? { marginTop: optionsMarginTop } : {}),
            ...(dropdownWidth ? { minWidth: dropdownWidth } : {}),
          }}
        >
          {secondaryOptions}
        </div>
      </div>
    )
  } else {
    return (
      <div
        className={`dropdown-area
        ${className}
        ${expanded ? 'show-options' : 'hide-options'}
      `}
      >
        {mainOption}
      </div>
    )
  }
}

function DropdownLink({ children, ...props }: { children: React.ReactNode }) {
  return (
    <SectionLink href="#" {...props}>
      <>
        {children}

        <svg
          className="dropdown-arrow"
          xmlns="http://www.w3.org/2000/svg"
          width="12px"
          height="12px"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="#000"
        >
          <polyline points="9 18 15 12 9 6"></polyline>
        </svg>
      </>
    </SectionLink>
  )
}

function SectionLink({
  children,
  $currentpage,
  href,
  isSelectedCheck,
  useNormalLink,
  ...props
}: {
  children: React.ReactNode
  $currentpage?: string
  isSelectedCheck?: () => boolean
  href: string
  useNormalLink?: boolean
}) {
  const isSelected = isSelectedCheck
    ? isSelectedCheck()
    : $currentpage === 'true'

  const linkClass = `styled-link ${isSelected ? 'current' : ''}`

  if (useNormalLink) {
    return (
      <a className={linkClass} href={href}>
        {children}
      </a>
    )
  } else if (href.startsWith('http')) {
    // External link - use normal 'a' tag
    return (
      <Link
        className={linkClass}
        href={href}
        target="_blank"
        prefetch={false}
        {...props}
      >
        {children}
      </Link>
    )
  } else {
    // Utilize next/link for more seamless browsing
    return (
      <Link
        className={linkClass}
        href={href}
        prefetch={false}
        {...props}
        passHref
      >
        {children}
      </Link>
    )
  }
}

function AdminModalLink({ children }: { children: React.ReactNode }) {
  const [modalVisible, setModalVisible] = useState(false)
  const { data: orgData, loading, error } = useOrganization()

  const toggleModal = useCallback(
    () => setModalVisible(visible => !visible),
    [setModalVisible],
  )
  return (
    <>
      <a
        href="#"
        onClick={(evt: React.MouseEvent) => {
          evt.preventDefault()
          setModalVisible(true)
        }}
      >
        {children}
      </a>

      <Modal
        noCloseButton
        noPadding
        noPrint
        toggleModal={toggleModal}
        modalOpen={modalVisible}
      >
        <div className={styles.adminModal}>
          <div className="header">
            <h2>Contact these administrators to request credits</h2>
            <div className="close" onClick={() => setModalVisible(false)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height={12}
                width={12}
                fill="none"
                viewBox="0 0 21.256 21.256"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                stroke="#1E749F"
              >
                <g transform="translate(10.628 -7.799) rotate(45)">
                  <line y2="26.06" transform="translate(13.03 0)"></line>
                  <line
                    y2="26.06"
                    transform="translate(26.06 13.03) rotate(90)"
                  ></line>
                </g>
              </svg>
            </div>
          </div>

          <div>
            {loading && <div>Loading list of administrators...</div>}

            {!loading && !orgData && error && (
              <div>Error loading organization adminsitrators!</div>
            )}

            {!loading && orgData && (
              <div className="pills">
                {(orgData.org.administrators || []).map(adminIdent => (
                  <div className="pill-wrapper" key={adminIdent.id}>
                    <IdentityIcon identity={adminIdent} />

                    <div className="admin-name">
                      {adminIdent.personalInfo?.fullName}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  )
}
