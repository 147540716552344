interface OwnProps {
  className?: string
  style?: React.CSSProperties
}

const ExclamationYellow = ({ className = '', style = {} }: OwnProps) => (
  <svg
    width="18"
    height="21"
    viewBox="0 0 18 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <circle
      stroke="none"
      cx="9"
      cy="11"
      r="9"
      fill="#FFCB1B"
      fill-opacity="0.3"
    />
    <path
      d="M10.0425 0.904499V8.6265C10.0425 9.0315 10.0335 9.4275 10.0155 9.8145C9.9975 10.2015 9.975 10.593 9.948 10.989C9.921 11.376 9.885 11.7765 9.84 12.1905C9.804 12.5955 9.759 13.032 9.705 13.5H8.085C8.031 13.032 7.9815 12.5955 7.9365 12.1905C7.9005 11.7765 7.869 11.376 7.842 10.989C7.815 10.593 7.7925 10.2015 7.7745 9.8145C7.7565 9.4275 7.7475 9.0315 7.7475 8.6265V0.904499H10.0425ZM7.167 18.765C7.167 18.531 7.2075 18.3105 7.2885 18.1035C7.3785 17.8965 7.5 17.7165 7.653 17.5635C7.806 17.4105 7.9815 17.289 8.1795 17.199C8.3865 17.109 8.6115 17.064 8.8545 17.064C9.0885 17.064 9.3045 17.109 9.5025 17.199C9.7095 17.289 9.8895 17.4105 10.0425 17.5635C10.1955 17.7165 10.317 17.8965 10.407 18.1035C10.497 18.3105 10.542 18.531 10.542 18.765C10.542 19.008 10.497 19.233 10.407 19.44C10.317 19.638 10.1955 19.8135 10.0425 19.9665C9.8895 20.1195 9.7095 20.2365 9.5025 20.3175C9.3045 20.4075 9.0885 20.4525 8.8545 20.4525C8.6115 20.4525 8.3865 20.4075 8.1795 20.3175C7.9815 20.2365 7.806 20.1195 7.653 19.9665C7.5 19.8135 7.3785 19.638 7.2885 19.44C7.2075 19.233 7.167 19.008 7.167 18.765Z"
      fill="#F09025"
      stroke="none"
    />
  </svg>
)

export default ExclamationYellow
