import useSWR from 'swr'
import useAuth from '@crystal-eyes/hooks/useAuth'
import { queryWithAuth, gql } from '@crystal-eyes/utils/apis/graphqlApi'
import { DiscType } from '@crystal-eyes/types'

type Identity = {
  id: string
  personalInfo: { fullName?: string; photoUrl?: string }
  rootSnapshot: { id: string }
  snapshot: { personality?: { discType: DiscType } }
}

export enum State {
  None = 'None',
  Loading = 'Loading',
  Error = 'Error',
  Loaded = 'Loaded',
}

export type Organization = {
  id: string
  name: string
  administrators: Identity[]
}

export type OrgState = {
  data: { org: Organization } | undefined
  loading?: boolean
  error?: any
}

const ORGANIZATION_QUERY = gql`
  query GetOrganization {
    org {
      id
      name
      administrators {
        id
        personalInfo {
          fullName
          photoUrl
        }

        rootSnapshot {
          id
        }

        snapshot {
          personality {
            discType
          }
        }
      }
    }
  }
`

export default function useOrganization(): OrgState {
  const { data: auth } = useAuth()
  const fetcher = ([_key, _authedUser]: [string, string]) =>
    queryWithAuth(auth, ORGANIZATION_QUERY)

  const {
    data,
    isLoading: loading,
    error,
  } = useSWR(['useOrganization', auth?.jwtPayload?.sub], fetcher, {
    keepPreviousData: true,
    revalidateOnFocus: false,
    refreshInterval: 1000 * 60 * 60 * 5,
  })

  return {
    data,
    loading,
    error,
  }
}
