import { StatsigClient } from '@statsig/js-client'
import useAuth from '@crystal-eyes/hooks/useAuth'
import logger from '@crystal-eyes/utils/logger'

type Options = {
  name: string
  allowUnauthed?: boolean
}

export default function useStatsigGate({ name, allowUnauthed }: Options) {
  const { data: auth } = useAuth()
  let gateOpen = false

  if (auth?.authed || allowUnauthed) {
    gateOpen = StatsigClient.instance().checkGate(name)
  }
  logger.info(`Statsig Gate ${name}`, gateOpen)
  return {
    data: gateOpen,
  }
}
