interface OwnProps {
  className?: string
  style?: React.CSSProperties
}

const CalendarDetailed = ({ className = '', style = {} }: OwnProps) => (
  <svg
    width="37"
    height="32"
    viewBox="0 0 37 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M36 31H0.999999L1 9H36V31Z"
      fill="#D9DFE2"
      stroke="#D9DFE2"
      strokeWidth="2"
    />
    <path
      d="M13.8703 12.5894H11.426C11.1498 12.5894 10.926 12.8132 10.926 13.0894V15.5001C10.926 15.7763 11.1498 16.0001 11.426 16.0001H13.8703C14.1465 16.0001 14.3703 15.7763 14.3703 15.5001V13.0894C14.3703 12.8132 14.1465 12.5894 13.8703 12.5894Z"
      fill="white"
      stroke="none"
    />
    <path
      d="M20.0186 12.5894H17.5741C17.298 12.5894 17.0741 12.8132 17.0741 13.0894V15.5001C17.0741 15.7763 17.298 16.0001 17.5741 16.0001H20.0186C20.2947 16.0001 20.5186 15.7763 20.5186 15.5001V13.0894C20.5186 12.8132 20.2947 12.5894 20.0186 12.5894Z"
      fill="white"
      stroke="none"
    />
    <path
      d="M26.1666 12.5894H23.7221C23.446 12.5894 23.2221 12.8132 23.2221 13.0894V15.5001C23.2221 15.7763 23.446 16.0001 23.7221 16.0001H26.1666C26.4427 16.0001 26.6666 15.7763 26.6666 15.5001V13.0894C26.6666 12.8132 26.4427 12.5894 26.1666 12.5894Z"
      fill="white"
      stroke="none"
    />
    <path
      d="M32.3148 12.5894H29.8703C29.5942 12.5894 29.3703 12.8132 29.3703 13.0894V15.5001C29.3703 15.7763 29.5942 16.0001 29.8703 16.0001H32.3148C32.5909 16.0001 32.8148 15.7763 32.8148 15.5001V13.0894C32.8148 12.8132 32.5909 12.5894 32.3148 12.5894Z"
      fill="white"
      stroke="none"
    />
    <path
      d="M7.72223 18.2373H5.27777C5.00163 18.2373 4.77777 18.4612 4.77777 18.7373V21.1481C4.77777 21.4242 5.00163 21.6481 5.27777 21.6481H7.72223C7.99837 21.6481 8.22223 21.4242 8.22223 21.1481V18.7373C8.22223 18.4612 7.99837 18.2373 7.72223 18.2373Z"
      fill="white"
      stroke="none"
    />
    <path
      d="M13.8703 18.2373H11.426C11.1498 18.2373 10.926 18.4612 10.926 18.7373V21.1481C10.926 21.4242 11.1498 21.6481 11.426 21.6481H13.8703C14.1465 21.6481 14.3703 21.4242 14.3703 21.1481V18.7373C14.3703 18.4612 14.1465 18.2373 13.8703 18.2373Z"
      fill="white"
      stroke="none"
    />
    <path
      d="M20.0186 18.2373H17.5741C17.298 18.2373 17.0741 18.4612 17.0741 18.7373V21.1481C17.0741 21.4242 17.298 21.6481 17.5741 21.6481H20.0186C20.2947 21.6481 20.5186 21.4242 20.5186 21.1481V18.7373C20.5186 18.4612 20.2947 18.2373 20.0186 18.2373Z"
      fill="white"
      stroke="none"
    />
    <path
      d="M26.1666 18.2373H23.7221C23.446 18.2373 23.2221 18.4612 23.2221 18.7373V21.1481C23.2221 21.4242 23.446 21.6481 23.7221 21.6481H26.1666C26.4427 21.6481 26.6666 21.4242 26.6666 21.1481V18.7373C26.6666 18.4612 26.4427 18.2373 26.1666 18.2373Z"
      fill="white"
      stroke="none"
    />
    <path
      d="M32.3148 18.2373H29.8703C29.5942 18.2373 29.3703 18.4612 29.3703 18.7373V21.1481C29.3703 21.4242 29.5942 21.6481 29.8703 21.6481H32.3148C32.5909 21.6481 32.8148 21.4242 32.8148 21.1481V18.7373C32.8148 18.4612 32.5909 18.2373 32.3148 18.2373Z"
      fill="white"
      stroke="none"
    />
    <path
      d="M7.72223 23.9219H5.27777C5.00163 23.9219 4.77777 24.1457 4.77777 24.4219V26.8326C4.77777 27.1088 5.00163 27.3326 5.27777 27.3326H7.72223C7.99837 27.3326 8.22223 27.1088 8.22223 26.8326V24.4219C8.22223 24.1457 7.99837 23.9219 7.72223 23.9219Z"
      fill="white"
      stroke="none"
    />
    <path
      d="M13.8703 23.9219H11.426C11.1498 23.9219 10.926 24.1457 10.926 24.4219V26.8326C10.926 27.1088 11.1498 27.3326 11.426 27.3326H13.8703C14.1465 27.3326 14.3703 27.1088 14.3703 26.8326V24.4219C14.3703 24.1457 14.1465 23.9219 13.8703 23.9219Z"
      fill="white"
      stroke="none"
    />
    <path
      d="M20.0186 23.9219H17.5741C17.298 23.9219 17.0741 24.1457 17.0741 24.4219V26.8326C17.0741 27.1088 17.298 27.3326 17.5741 27.3326H20.0186C20.2947 27.3326 20.5186 27.1088 20.5186 26.8326V24.4219C20.5186 24.1457 20.2947 23.9219 20.0186 23.9219Z"
      fill="white"
      stroke="none"
    />
    <path
      d="M26.1666 23.9219H23.7221C23.446 23.9219 23.2221 24.1457 23.2221 24.4219V26.8326C23.2221 27.1088 23.446 27.3326 23.7221 27.3326H26.1666C26.4427 27.3326 26.6666 27.1088 26.6666 26.8326V24.4219C26.6666 24.1457 26.4427 23.9219 26.1666 23.9219Z"
      fill="white"
      stroke="none"
    />
    <path d="M1 3H36V8H1V3Z" fill="#607986" stroke="#607986" strokeWidth="2" />
    <path
      d="M9.20371 0H8.05554C7.7794 0 7.55554 0.223858 7.55554 0.500001V3.42421C7.55554 3.70035 7.7794 3.92421 8.05554 3.92421H9.20371C9.47985 3.92421 9.70371 3.70035 9.70371 3.42421V0.5C9.70371 0.223858 9.47985 0 9.20371 0Z"
      fill="#D9DFE2"
      stroke="none"
    />
    <path
      d="M28.6481 0H27.5C27.2238 0 27 0.223858 27 0.500001V3.42421C27 3.70035 27.2238 3.92421 27.5 3.92421H28.6481C28.9243 3.92421 29.1481 3.70035 29.1481 3.42421V0.5C29.1481 0.223858 28.9243 0 28.6481 0Z"
      fill="#D9DFE2"
      stroke="none"
    />
  </svg>
)

export default CalendarDetailed
