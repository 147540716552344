interface OwnProps {
  className?: string
  style?: React.CSSProperties
}

const CheckmarkToggle = ({ className = '', style = {} }: OwnProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 29 29"
    fill="none"
    className={className}
    style={style}
  >
    <circle stroke="none" cx="14.5" cy="14.5" r="14.5" fill="#11B21B" />
    <path
      d="M5 17.4202L11.7538 23.2092L22.56 7"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default CheckmarkToggle
