import React from 'react'
import styles from './Toggle.module.scss'
import FixedColorIcon, {
  FixedColorIcons,
} from './Icon/FixedColorIcon/FixedColorIcon'

interface OwnProps {
  checked: boolean
  onClick?: () => void
}

function Toggle({ checked, onClick }: OwnProps) {
  return (
    <div
      onClick={() => onClick && onClick()}
      className={`${styles.toggleContainer} ${checked ? styles.checked : ''}`}
    >
      <div className={styles.toggle} data-testid="main-toggle">
        <FixedColorIcon
          icon={
            checked ? FixedColorIcons.CheckmarkToggle : FixedColorIcons.XToggle
          }
        />
      </div>
    </div>
  )
}

export default Toggle
