import useSWR from 'swr'
import { DiscType, Personality } from '@crystal-eyes/types'
import { archetypes, Archetype } from '@crystal-eyes/disc'
import useAuth from '@crystal-eyes/hooks/useAuth'
import { useMemo } from 'react'
import {
  queryWithAuth,
  queryWithoutAuth,
  gql,
} from '@crystal-eyes/utils/apis/graphqlApi'

interface Identity {
  id: string
  snapshot?: {
    personality?: {
      discType?: DiscType
      discCircumplex?: { degrees: number; intensity: number }
      discSignals?: { degrees: number; intensity: number }
      predictionDiscSignals?: { degrees: number; intensity: number }
      prediction?: {
        discType?: DiscType
        discCircumplex?: { degrees: number; intensity: number }
      }
    }
  }
}

type Options = {
  identity?: Identity
}

export type State = {
  data?: Personality & { archetype: Archetype }
  loading?: boolean
  error?: any
  mutate: () => void
}

export default function usePersonality({ identity }: Options = {}): State {
  const { data: auth } = useAuth()
  const fetcher = ([_key, _authedUser, identityId]: [
    string,
    string,
    string,
  ]) => {
    return auth?.authToken
      ? queryWithAuth(auth, PERSONALITY_QUERY, { id: identityId })
      : queryWithoutAuth(PUBLIC_PERSONALITY_QUERY, { id: identityId })
  }

  const query = identity
    ? ['usePersonality', auth?.jwtPayload?.sub, identity.id]
    : null

  const {
    data: queryData,
    isLoading: loading,
    error: error,
    mutate,
  } = useSWR(query, fetcher, {
    revalidateOnFocus: false,
    keepPreviousData: true,
    fallbackData: { identity },
  })

  const data = useMemo(() => {
    let personality =
      queryData?.identity?.snapshot?.personality ||
      queryData?.public?.identity?.snapshot?.personality
    if (!personality) return undefined

    if (personality && identity?.snapshot?.personality) {
      personality = {
        ...identity?.snapshot?.personality,
        ...personality,
      }
    }

    return {
      ...personality,
      archetype: personality.discType
        ? archetypes[personality.discType as DiscType]
        : undefined,
    }
  }, [identity, queryData])

  return {
    data,
    loading,
    error,
    mutate,
  }
}

const PERSONALITY_QUERY = gql`
  query UsePersonality($id: Guid!) {
    identity(id: $id) {
      snapshot {
        personality {
          id
          discType
          discArchetype
          prediction {
            discType

            discCircumplex {
              degrees
              intensity
            }
          }

          discCircumplex {
            degrees
            intensity
          }

          discSignals {
            degrees
            intensity
          }

          predictionDiscSignals {
            degrees
            intensity
          }
        }
      }
    }
  }
`

const PUBLIC_PERSONALITY_QUERY = gql`
  query UsePersonalityPublic($id: Guid!) {
    public {
      identity(id: $id) {
        snapshot: rootSnapshot {
          personality {
            id
            discType

            discCircumplex {
              degrees
              intensity
            }
          }
        }
      }
    }
  }
`
