interface OwnProps {
  className?: string
  style?: React.CSSProperties
}

const RedXCircle = ({ className = '', style = {} }: OwnProps) => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <circle stroke="none" cx="5" cy="5" r="5" fill="#FBECED" />
    <line
      x1="8.49414"
      y1="1.54011"
      x2="1.54092"
      y2="8.49333"
      stroke="#DB3C48"
      strokeLinecap="round"
    />
    <line
      x1="8.37688"
      y1="8.49414"
      x2="1.42366"
      y2="1.54092"
      stroke="#DB3C48"
      strokeLinecap="round"
    />
  </svg>
)

export default RedXCircle
