interface OwnProps {
  className?: string
  style?: React.CSSProperties
}

const RefreshDetailed = ({ className = '', style = {} }: OwnProps) => (
  <svg viewBox="0 0 151.898 168.148" className={className} style={style}>
    <g transform="translate(-112.373 -6898.606)">
      <path
        d="M134.378,60.7,107.3,32.088a6.812,6.812,0,0,0-9.632-.266L69.058,58.9l9.368,9.9L94.58,53.509A40.482,40.482,0,1,1,65.241,15.2l6.552,1.87L75.532,3.964,68.98,2.095a54.114,54.114,0,1,0,39.241,50.791l16.259,17.18Z"
        transform="translate(113.684 7026.938) rotate(-71)"
      />
      <path
        d="M134.378,60.7,107.3,32.088a6.812,6.812,0,0,0-9.632-.266L69.058,58.9l9.368,9.9L94.58,53.509A40.482,40.482,0,1,1,65.241,15.2l6.552,1.87L75.532,3.964,68.98,2.095a54.114,54.114,0,1,0,39.241,50.791l16.259,17.18Z"
        transform="translate(116.911 7030.165) rotate(-71)"
        style={{ fill: 'var(--color-glass)', stroke: 'none' }}
      />
    </g>
  </svg>
)

export default RefreshDetailed
