import { getDocumentCookie } from '@crystal-eyes/lib/browser'
import { AUTH_TOKEN_COOKIE } from '@crystal-eyes/lib/constants'
import jwtDecode from 'jwt-decode'

export function getAuthToken(): string | null {
  const token = getDocumentCookie(AUTH_TOKEN_COOKIE)
  return token
}

export function isLoggedIn(): boolean {
  const token = getAuthToken()
  return !!token
}

export function loggedInUserId(): string | undefined {
  const token = getAuthToken()
  const jwtDetails: {sub: string} | undefined = token != null ? jwtDecode(token) : undefined
  return jwtDetails?.sub.split(':')[1]
}