interface OwnProps {
  className?: string
  style?: React.CSSProperties
}

const Browser = ({ className = '', style = {} }: OwnProps) => (
  <svg
    width="56"
    height="42"
    viewBox="0 0 56 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <rect
      y="0.5"
      width="56"
      height="41"
      rx="8"
      stroke="none"
      fill="#2CA7E4"
      fillOpacity="0.2"
    />
    <path
      d="M43.82 8.5H13V33.8354H43.82V8.5Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.2178 14.6699H43.4768"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.2071 12.9874C17.9644 12.9874 18.5783 12.3736 18.5783 11.6163C18.5783 10.859 17.9644 10.2451 17.2071 10.2451C16.4498 10.2451 15.8359 10.859 15.8359 11.6163C15.8359 12.3736 16.4498 12.9874 17.2071 12.9874Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.8507 12.9874C22.6079 12.9874 23.2218 12.3736 23.2218 11.6163C23.2218 10.859 22.6079 10.2451 21.8507 10.2451C21.0934 10.2451 20.4795 10.859 20.4795 11.6163C20.4795 12.3736 21.0934 12.9874 21.8507 12.9874Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.5245 12.9874C27.2818 12.9874 27.8956 12.3736 27.8956 11.6163C27.8956 10.859 27.2818 10.2451 26.5245 10.2451C25.7672 10.2451 25.1533 10.859 25.1533 11.6163C25.1533 12.3736 25.7672 12.9874 26.5245 12.9874Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.5191 31.8097C32.6497 31.8097 35.9982 28.4612 35.9982 24.3306C35.9982 20.2001 32.6497 16.8516 28.5191 16.8516C24.3885 16.8516 21.04 20.2001 21.04 24.3306C21.04 28.4612 24.3885 31.8097 28.5191 31.8097Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.8789 29.2237C23.0036 27.3539 23.284 27.1981 23.4398 27.0735C24.0943 26.606 24.8422 26.2632 25.6524 26.1074C27.1482 27.6656 29.6412 27.6967 31.1994 26.2009L31.2929 26.1074C32.1031 26.2632 32.851 26.606 33.5054 27.0735C33.6924 27.1981 34.004 27.3228 34.1287 29.286"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.3549 21.6195C31.3549 23.1777 30.3577 25.4837 28.4567 25.4837C26.5558 25.4837 25.5586 23.1777 25.5586 21.6195C25.6521 20.0302 27.0233 18.8149 28.6126 18.9084C30.1084 18.9707 31.2614 20.1549 31.3549 21.6195Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Browser
