interface OwnProps {
  className?: string
  style?: React.CSSProperties
}

const LinkedInGray = ({ className = '', style = {} }: OwnProps) => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <g clipPath="url(#clip0_998_2643)">
      <path
        stroke="none"
        d="M21.5812 22.0649H17.8298V16.1833C17.8298 14.78 17.8007 12.9777 15.8756 12.9777C13.9201 12.9777 13.6212 14.4975 13.6212 16.0807V22.0624H9.86983V9.97741H13.473V11.6239H13.5212C13.8825 11.0075 14.4041 10.5005 15.0305 10.1569C15.6569 9.81321 16.3648 9.64571 17.0788 9.67218C20.8783 9.67218 21.5824 12.1735 21.5824 15.4297V22.0598L21.5812 22.0649ZM5.63339 8.32336C5.2026 8.32336 4.78149 8.19563 4.42328 7.95632C4.06508 7.71702 3.78587 7.37687 3.62095 6.9789C3.45604 6.58093 3.41283 6.14299 3.49678 5.72046C3.58073 5.29793 3.78807 4.90978 4.0926 4.60508C4.39712 4.30038 4.78515 4.09281 5.20764 4.00861C5.63012 3.92442 6.06808 3.96737 6.46615 4.13206C6.86421 4.29674 7.20452 4.57575 7.44403 4.93382C7.68355 5.29188 7.81152 5.71292 7.81177 6.14371C7.81211 6.42994 7.756 6.71341 7.64666 6.97793C7.53732 7.24244 7.37689 7.4828 7.17456 7.68525C6.97223 7.8877 6.73196 8.04826 6.46751 8.15776C6.20306 8.26725 5.91961 8.32353 5.63339 8.32336ZM7.51414 22.0649H3.75264V9.97741H7.51414V22.0649ZM23.4556 0.478659H1.86809C1.62559 0.475815 1.38491 0.520766 1.15978 0.610946C0.934659 0.701126 0.729503 0.834768 0.556032 1.00424C0.382561 1.17371 0.244171 1.3757 0.148767 1.59866C0.0533622 1.82162 0.002811 2.06119 0 2.30369L0 23.9836C0.002811 24.2261 0.0533622 24.4657 0.148767 24.6887C0.244171 24.9116 0.382561 25.1136 0.556032 25.2831C0.729503 25.4525 0.934659 25.5862 1.15978 25.6764C1.38491 25.7666 1.62559 25.8115 1.86809 25.8087H23.4543C23.9449 25.8147 24.4178 25.626 24.7695 25.2839C25.1211 24.9418 25.3227 24.4742 25.33 23.9836V2.30369C25.3227 1.81314 25.1211 1.34553 24.7695 1.0034C24.4178 0.661278 23.9449 0.472568 23.4543 0.478659H23.4556Z"
        fill="#B3BFC5"
      />
    </g>
    <defs>
      <clipPath id="clip0_998_2643">
        <rect
          width="25.33"
          height="25.33"
          fill="white"
          transform="translate(0 0.478516)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default LinkedInGray
