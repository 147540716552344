interface OwnProps {
  className?: string
  style?: React.CSSProperties
}

const DetailedMonitor = ({ className = '', style = {} }: OwnProps) => (
  <svg viewBox="0 0 132.753 113.767" className={className} style={style}>
    <g transform="translate(-147 -4998)">
      <g transform="translate(148 4999)">
        <g transform="translate(0 0)">
          <rect width="127.34" height="90.122" />
          <line x2="6.829" transform="translate(59.128 5.956)" />
          <g transform="translate(0 81.196)">
            <rect width="127.34" height="8.927" />
          </g>
          <g transform="translate(35.751 103.498)">
            <path
              d="M47.233,122.284H98.967a4.338,4.338,0,0,1,4.338,4.338v3.931H42.9v-3.931A4.338,4.338,0,0,1,47.233,122.284Z"
              transform="translate(-42.895 -122.284)"
            />
          </g>
          <g transform="translate(51.197 90.707)">
            <path
              d="M89.3,120.087H61L65.934,107.3H84.362Z"
              transform="translate(-60.995 -107.295)"
            />
          </g>
        </g>
        <rect
          width="127.34"
          height="79.882"
          transform="translate(3.413 3.414)"
          style={{
            fill: 'var(--color-glass)',
            stroke: 'none',
          }}
        />
      </g>
    </g>
  </svg>
)

export default DetailedMonitor
