'use client'

import React, { useCallback, useEffect, useState } from 'react'
import Link from 'next/link'
import styles from './LoginForm.module.scss'
import useLogin, { LoginErrors } from '@crystal-eyes/hooks/useLogin'
import { match } from 'ts-pattern'
import { redirect } from 'next/navigation'
import { debounce } from 'throttle-debounce'
import { getSSODetails } from '@crystal-eyes/utils/apis/authApi'
import { authApiUrl } from '@crystal-eyes/config'
import { isLoggedIn } from '@crystal-eyes/components/contexts/AuthContextClient'
import { useRouter } from 'next/navigation'

export default function LoginForm() {
  const router = useRouter()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [ssoUrl, setSsoUrl] = useState<string | null>(null)
  const [ssoConfirmation, setSsoConfirmation] = useState<boolean>(false)
  const [twoFactorCode, setTwoFactorCode] = useState('')
  const { success, loading, error, doLogin } = useLogin(tokens => {
    if (tokens.refresh) {
      router.push(
        `/app/login/status?login_success=true&login_refresh=${tokens.refresh}`,
      )
    }
  })

  if (isLoggedIn()) redirect('/app/home')

  const checkEmailSSO = useCallback(
    debounce(500, async email => {
      const myHost = `${window.location.protocol}//${window.location.host}`

      if (email) {
        getSSODetails({
          email,
          redirectUri: `${myHost}/app/login/status`,
        }).then(resp => {
          if (resp?.url) {
            const url = new URL(resp.url)
            if (resp.force) {
              url.protocol = window.location.protocol
              router.push(url.toString())
            } else {
              setSsoUrl(url.toString())
              setSsoConfirmation(true)
            }
          }
        })
      }
    }),
    [],
  )

  useEffect(() => {
    checkEmailSSO(email)
  }, [email])

  const onGoogleSignIn = useCallback(() => {
    const myHost = `${window.location.protocol}//${window.location.host}`

    // NOTE: Currently used to drop a Redirect cookie on the user's browser for after auth
    getSSODetails({
      email,
      redirectUri: `${myHost}/app/login/status`,
    }).then(() => {
      router.push(`${authApiUrl}/login/google`)
    })
  }, [router])

  const onSubmit = useCallback(
    (evt: React.FormEvent) => {
      evt.preventDefault()

      const myHost = `${window.location.protocol}//${window.location.host}`
      doLogin(email, password, {
        twoFactorCode,
        redirectUri: `${myHost}/app/login/status`,
      })
    },
    [email, password, twoFactorCode, doLogin],
  )

  const state =
    error !== LoginErrors.NeedTwoFactor ? 'credentials' : 'two_factor'

  const errorMessage = match(error)
    .with(
      LoginErrors.Invalid,
      () => 'Login failed. Please check your email and password.',
    )
    .with(LoginErrors.Unknown, () => 'Server Error. Please try again shortly')
    .with(LoginErrors.NeedTwoFactor, () => '')
    .otherwise(() => '')

  return (
    <form className={styles.form} onSubmit={onSubmit}>
      {state === 'credentials' && (
        <>
          <label htmlFor="login-email">Email</label>
          <input
            type="email"
            name="email"
            id="login-email"
            value={email}
            onChange={evt => setEmail(evt.target.value)}
            required
          />

          <label htmlFor="login-password">Password</label>
          <input
            type="password"
            name="password"
            id="login-password"
            value={password}
            onChange={evt => setPassword(evt.target.value)}
            required
          />

          <div className={styles.forgotPassword}>
            <Link prefetch={false} href="/app/password">
              Forgot Password
            </Link>
          </div>
        </>
      )}

      {state === 'two_factor' && (
        <>
          <div className={styles.twoFactorMessage}>
            Check your authenticator mobile app for your authentication code
          </div>
          <label htmlFor="login-two-factor">Two-Factor Auth Code</label>
          <input
            type="number"
            name="two-factor"
            id="login-two-factor"
            value={twoFactorCode}
            onChange={evt => setTwoFactorCode(evt.target.value)}
            placeholder="042727"
            required
          />
        </>
      )}

      {errorMessage && (
        <div className={styles.errorMessage}>{errorMessage}</div>
      )}

      {ssoConfirmation && ssoUrl && (
        <a
          href={ssoUrl}
          className={`CE action-button tertiary ${styles.ssoLink}`}
        >
          Sign in with SSO
        </a>
      )}

      <button
        type="submit"
        disabled={success || loading || !email || !password}
      >
        {loading || success ? 'Submitting...' : 'Sign In'}
      </button>

      {state === 'credentials' && (
        <>
          <div className={styles.googleLoginCta}>
            <b>OR</b>

            <div className={styles.ssoButtons}>
              <button
                className={styles.gsiMaterialButton}
                onClick={onGoogleSignIn}
                type="button"
              >
                <div className={styles.gsiMaterialButtonState}></div>
                <div className={styles.gsiMaterialButtonContentWrapper}>
                  <div className={styles.gsiMaterialButtonIcon}>
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 48 48"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      style={{ display: 'block' }}
                    >
                      <path
                        fill="#EA4335"
                        d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                      ></path>
                      <path
                        fill="#4285F4"
                        d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                      ></path>
                      <path
                        fill="#FBBC05"
                        d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                      ></path>
                      <path
                        fill="#34A853"
                        d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                      ></path>
                      <path fill="none" d="M0 0h48v48H0z"></path>
                    </svg>
                  </div>
                  <span className={styles.gsiMaterialButtonContents}>
                    Sign in with Google
                  </span>
                  <span style={{ display: 'none' }}>Sign in with Google</span>
                </div>
              </button>
            </div>
          </div>
        </>
      )}

      <div className={styles.registerCta}>
        <span>Don't have an account?</span>
        <Link prefetch={false} href="/app/register">
          Sign up
        </Link>
      </div>
    </form>
  )
}
