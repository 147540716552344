interface OwnProps {
  className?: string
  style?: React.CSSProperties
}

const InfoBlue = ({ className = '', style = {} }: OwnProps) => (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <circle
      stroke="none"
      cx="9"
      cy="10.5"
      r="9"
      fill="#A2E2FC"
      fillOpacity="0.5"
    />
    <path
      stroke="none"
      d="M9.6915 6.0745V19.75H7.2885V6.0745H9.6915ZM10.2045 1.7815C10.2045 2.0155 10.155 2.236 10.056 2.443C9.966 2.641 9.84 2.821 9.678 2.983C9.525 3.136 9.3405 3.2575 9.1245 3.3475C8.9175 3.4375 8.697 3.4825 8.463 3.4825C8.229 3.4825 8.0085 3.4375 7.8015 3.3475C7.6035 3.2575 7.428 3.136 7.275 2.983C7.122 2.821 7.0005 2.641 6.9105 2.443C6.8205 2.236 6.7755 2.0155 6.7755 1.7815C6.7755 1.5475 6.8205 1.327 6.9105 1.12C7.0005 0.903999 7.122 0.719499 7.275 0.566498C7.428 0.404499 7.6035 0.278499 7.8015 0.188498C8.0085 0.0984985 8.229 0.0534985 8.463 0.0534985C8.697 0.0534985 8.9175 0.0984985 9.1245 0.188498C9.3405 0.278499 9.525 0.404499 9.678 0.566498C9.84 0.719499 9.966 0.903999 10.056 1.12C10.155 1.327 10.2045 1.5475 10.2045 1.7815Z"
      fill="#2CA7E4"
    />
  </svg>
)

export default InfoBlue
