interface OwnProps {
  className?: string
  style?: React.CSSProperties
}

const EyeStrikethrough = ({ className = '', style = {} }: OwnProps) => (
  <svg viewBox="0 0 139.524 134.933" className={className} style={style}>
    <g transform="translate(-111 -189.586)">
      <g transform="translate(112 191)">
        <g transform="translate(0 33.596)">
          <path
            d="M4205.625,857.7s-30.786-36.372-68.763-36.372S4068.1,857.7,4068.1,857.7s30.786,36.949,68.761,36.949S4205.625,857.7,4205.625,857.7Z"
            transform="translate(-4068.101 -821.326)"
          />
          <path
            d="M4149.544,854.669a25.855,25.855,0,1,0-25.856,25.856A25.835,25.835,0,0,0,4149.544,854.669Z"
            transform="translate(-4054.927 -818.008)"
          />
          <path
            d="M4205.625,857.7s-30.786-36.372-68.763-36.372S4068.1,857.7,4068.1,857.7s30.786,36.949,68.761,36.949S4205.625,857.7,4205.625,857.7Z"
            transform="translate(-4068.101 -821.326)"
          />
        </g>
        <line x1="132.105" y2="132.105" transform="translate(3.976 0)" />
        <path
          d="M4149.544,854.669a25.855,25.855,0,1,0-25.856,25.856A25.835,25.835,0,0,0,4149.544,854.669Z"
          transform="translate(-4052.04 -781.526)"
          style={{
            fill: 'var(--color-glass)',
            stroke: 'none',
          }}
        />
      </g>
    </g>
  </svg>
)

export default EyeStrikethrough
