'use client'
import FrontendTracer from '@crystal-eyes/utils/FrontendTracer';
import { useEffect, useState } from 'react'
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web'
import { Context, Span, trace, ROOT_CONTEXT } from '@opentelemetry/api'
import { ReadableSpan } from '@opentelemetry/sdk-trace-base/build/src/export/ReadableSpan'
import { AuthDetails } from '@crystal-eyes/types'
import { setRootSpan } from '@crystal-eyes/utils/instrumentation'
import useAuth from '@crystal-eyes/hooks/useAuth'
import { usePathname } from 'next/navigation'

let auth: AuthDetails | null = null

export default function Opentelemetry() {
  const [provider, setProvider] = useState<WebTracerProvider | null>(null)
  const { data: authData } = useAuth()
  const pathName = usePathname()

  useEffect(() => {
    FrontendTracer('dashboard-frontend').then((provider) => setProvider(provider))
  }, [])

  useEffect(() => {
    if (authData) auth = authData
  }, [authData])

  useEffect(() => {
    if (provider) {
      provider.addSpanProcessor({
        forceFlush: async () => {
        },
        onStart: (span: Span, _parentContext: Context) => {
          span.setAttribute('user.name', auth?.jwtPayload?.name || '')
        },
        onEnd: (_span: ReadableSpan) => {
        },
        shutdown: async () => {
        },
      })
    }
  }, [provider])

  useEffect(() => {
    if (pathName) {
      const tracer = trace.getTracer('Page Context')
      setRootSpan((currRoot) => {
        if (currRoot && currRoot.isRecording()) currRoot.end()
        const newRoot = tracer.startSpan(`Page: ${pathName}`, {
          root: true,
          attributes: {
            'page.path': pathName || ""
          }
        }, ROOT_CONTEXT)

        setTimeout(() => {
          if (newRoot && newRoot.isRecording()) newRoot.end()
        }, 5_000)

        return newRoot
      })
    }
  }, [pathName])


  useEffect(() => {
    const handleUnload = () => {
      setRootSpan((currRoot) => {
        if (currRoot && currRoot.isRecording()) currRoot.end()
        return null
      })
    }

    window.addEventListener('unload', handleUnload)
    return () => {
      window.removeEventListener('unload', handleUnload)
    }
  }, [])

  return <span className="opentelemetry-listener"></span>
}
