interface OwnProps {
  className?: string
  style?: React.CSSProperties
}

const MagnifyingGlassColor = ({ className = '', style = {} }: OwnProps) => (
  <svg
    width="100"
    height="103"
    viewBox="0 0 100 103"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="none"
    className={className}
    style={style}
  >
    <path
      d="M68 61L95.6865 88.6004C98.7864 91.8004 98.7864 96.8004 95.6865 100C92.4865 103.1 87.4865 103.1 84.2865 100L54 71"
      fill="#F09025"
      fillOpacity="0.5"
      stroke="none"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.823 54.4479L11.8879 54.3996C11.8667 54.4155 11.845 54.4316 11.823 54.4479Z"
      fill="#FFCB1B"
      fillOpacity="0.5"
      stroke="none"
    />
    <path
      d="M11.8879 54.3996C15.0879 51.9996 18.8879 50.3996 22.7879 49.5996C30.1879 57.2996 42.3879 57.4996 50.0879 50.0996L50.5879 49.5996C54.4879 50.3996 58.2879 51.9996 61.4879 54.3996C62.3879 54.9996 63.8879 55.6996 64.5879 65.2996C64.5879 65.2996 54.5 77.5 36.5 77.5C18.5 77.5 9.08789 64.9996 9.08789 64.9996C9.67197 56.0437 11.0142 55.048 11.823 54.4479L11.8879 54.3996Z"
      fill="#FFCB1B"
      fillOpacity="0.5"
      stroke="none"
    />
    <path
      d="M50.9877 27.6C50.9877 35.3 46.0877 46.6 36.6877 46.6C27.2877 46.6 22.3877 35.3 22.3877 27.6C22.8877 19.7 29.5877 13.7 37.4877 14.2C44.6877 14.6 50.4877 20.4 50.9877 27.6Z"
      fill="#FFCB1B"
      fillOpacity="0.5"
      stroke="none"
    />
    <path
      d="M69.7868 58.2002L95.6868 83.6002C98.7868 86.8002 98.7868 91.8002 95.6868 95.0002C92.4868 98.1002 87.4868 98.1002 84.2868 95.0002L58.2539 69.5411"
      stroke="#F09025"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M70.2871 70.2002L87.2871 87.1002"
      stroke="#F09025"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.0879 61.9996C11.6879 52.7996 13.0879 51.9996 13.8879 51.3996C17.0879 48.9996 20.8879 47.3996 24.7879 46.5996C32.1879 54.2996 44.3879 54.4996 52.0879 47.0996L52.5879 46.5996C56.4879 47.3996 60.2879 48.9996 63.4879 51.3996C64.3879 51.9996 65.8879 52.6996 66.5879 62.2996"
      stroke="#FFCB1B"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M52.9877 24.6C52.9877 32.3 48.0877 43.6 38.6877 43.6C29.2877 43.6 24.3877 32.3 24.3877 24.6C24.8877 16.7 31.5877 10.7 39.4877 11.2C46.6877 11.6 52.4877 17.4 52.9877 24.6Z"
      stroke="#FFCB1B"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.8873 74.8C59.2666 74.8 75.7873 58.2793 75.7873 37.9C75.7873 17.5207 59.2666 1 38.8873 1C18.508 1 1.9873 17.5207 1.9873 37.9C1.9873 58.2793 18.508 74.8 38.8873 74.8Z"
      stroke="#01293D"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default MagnifyingGlassColor
