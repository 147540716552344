interface OwnProps {
  className?: string
  style?: React.CSSProperties
}

const CheckmarkInBox = ({ className = '', style = {} }: OwnProps) => (
  <svg
    width="56"
    height="41"
    viewBox="0 0 56 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <rect stroke="none" width="56" height="41" rx="8" fill="#80CAEF" />
    <path
      d="M33.583 19.5837C33.9997 20.5837 34.2497 21.7503 34.1663 22.8337C34.1663 27.7503 30.1663 31.7503 25.2497 31.7503C20.333 31.7503 16.333 27.7503 16.333 22.8337C16.333 17.917 20.333 13.917 25.2497 13.917C26.9997 13.917 28.7497 14.417 30.2497 15.417"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.1667 12.833L25.8333 27.1663L19.25 22.833L22.5833 19.4997L25.9167 22.9164L35.6667 8.83301L40.1667 12.833Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default CheckmarkInBox
