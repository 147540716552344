interface OwnProps {
  className?: string
  style?: React.CSSProperties
}

const BrowserGray = ({ className = '', style = {} }: OwnProps) => (
  <svg
    width="40"
    height="33"
    viewBox="0 0 40 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <g filter="url(#filter0_d_386_10773)">
      <path
        d="M37.4945 1H1V31H37.4945V1Z"
        stroke="#80949E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.2583 8.30615H37.0885"
        stroke="#80949E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.98152 6.31364C6.87822 6.31364 7.60514 5.58672 7.60514 4.69002C7.60514 3.79332 6.87822 3.06641 5.98152 3.06641C5.08483 3.06641 4.35791 3.79332 4.35791 4.69002C4.35791 5.58672 5.08483 6.31364 5.98152 6.31364Z"
        stroke="#80949E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4796 6.31364C12.3763 6.31364 13.1032 5.58672 13.1032 4.69002C13.1032 3.79332 12.3763 3.06641 11.4796 3.06641C10.5829 3.06641 9.85596 3.79332 9.85596 4.69002C9.85596 5.58672 10.5829 6.31364 11.4796 6.31364Z"
        stroke="#80949E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0147 6.31364C17.9114 6.31364 18.6383 5.58672 18.6383 4.69002C18.6383 3.79332 17.9114 3.06641 17.0147 3.06641C16.118 3.06641 15.3911 3.79332 15.3911 4.69002C15.3911 5.58672 16.118 6.31364 17.0147 6.31364Z"
        stroke="#80949E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.3766 28.6013C24.2677 28.6013 28.2327 24.6363 28.2327 19.7452C28.2327 14.8542 24.2677 10.8892 19.3766 10.8892C14.4855 10.8892 10.5205 14.8542 10.5205 19.7452C10.5205 24.6363 14.4855 28.6013 19.3766 28.6013Z"
        stroke="#80949E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6973 25.5389C12.8449 23.3249 13.177 23.1404 13.3615 22.9928C14.1364 22.4393 15.022 22.0334 15.9814 21.8489C17.7526 23.6939 20.7046 23.7308 22.5497 21.9596L22.6604 21.8489C23.6198 22.0334 24.5054 22.4393 25.2803 22.9928C25.5017 23.1404 25.8707 23.288 26.0183 25.6127"
        stroke="#80949E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.7341 16.5352C22.7341 18.3802 21.5533 21.1108 19.3023 21.1108C17.0514 21.1108 15.8706 18.3802 15.8706 16.5352C15.9813 14.6532 17.6049 13.2141 19.4868 13.3248C21.2581 13.3986 22.6234 14.8008 22.7341 16.5352Z"
        stroke="#80949E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_386_10773"
        x="0"
        y="0"
        width="39.4946"
        height="33"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1" dy="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.00392157 0 0 0 0 0.160784 0 0 0 0 0.239216 0 0 0 0.6 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_386_10773"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_386_10773"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)

export default BrowserGray
